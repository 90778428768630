import React from 'react';
import { StepLabel } from '@mui/material';
import { OrderPageTypography } from './OrderPageTypography';

export const OrderPageLabel = ({ name }: { name: string }) => {
  return (
    <StepLabel>
      <OrderPageTypography color="primary" variant="body2">
        {name}
      </OrderPageTypography>
    </StepLabel>
  );
};
