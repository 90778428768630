import './ToggleButtonGroupUiObject.scss';
import './Ribbon.scss';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useLanguage } from '../../common/GeneralUtils';
import OrderUtils from '../OrderUtils';
import { OrderPageToggleButton } from '../themedComponents/OrderPageToggleButton';
import { OrderPageTypography } from '../themedComponents/OrderPageTypography';

const ToggleButtonGroupUiObjectDynamicPrice = (props) => {
  const { uiObject, orderState, setOrderDetailValue } = props;
  const { t } = useTranslation();
  const language = useLanguage();

  const getDefaultValue = () => {
    return `${orderState.details[uiObject.data_type]}`;
  };

  const [value, setValue] = useState(getDefaultValue());

  const onValueChange = (e, val) => {
    if (val != null) {
      // for deselection
      setValue(val);
      setOrderDetailValue(uiObject.data_type, val);
    }
  };

  const getPrice = (value) => {
    const tempDetails = {
      ...orderState.details,
      [uiObject.data_type]: value,
    };

    const plugins = orderState.orderPage.plugins.filter((p) => p.type !== 'portEntranceFee'); // This is specific to this plugin on this stage.
    return OrderUtils.calculatePrice(orderState.orderPage.price, tempDetails, orderState.dateAndTimeDetails, plugins);
  };

  return (
    <Box className="toggle-button-ui-object" sx={{ marginBottom: 0 }}>
      <div className="text-label" dangerouslySetInnerHTML={{ __html: uiObject.label[language] }} />

      <Box className="toggle-button-container" sx={{ display: 'inline-block', marginBottom: '0!important' }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left" />
              <TableCell align="center">
                <OrderPageTypography>{t('order_page.table.price')}</OrderPageTypography>
              </TableCell>
              <TableCell align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {uiObject.options.map((option) => {
              return (
                <TableRow key={option.value} onClick={(e) => onValueChange(e, option.value)}>
                  <TableCell align="left" sx={{ paddingInlineStart: 0 }}>
                    <OrderPageToggleButton
                      sx={{ width: '140px', height: '60px' }}
                      className="toggle-button"
                      color="primary"
                      selected={option.value === value}
                      value={`${option.value}`}>
                      <Grid item>
                        <Grid item className="ribbon" hidden={option.value !== 10800}>
                          <OrderPageTypography variant="body2" color="primary">
                            {t('recommended')}
                          </OrderPageTypography>
                        </Grid>
                        {option.label[language]}
                      </Grid>
                    </OrderPageToggleButton>
                  </TableCell>
                  <TableCell align="center">
                    <OrderPageTypography>{getPrice(option.value)} &#8362;</OrderPageTypography>
                  </TableCell>
                  <TableCell align="center">
                    <div className="secondary-text">
                      <OrderPageTypography variant={'body2'}>
                        {Math.floor(getPrice(option.value) / orderState.details.persons)}
                        &#8362;/ {t('order_page.table.participant')}
                      </OrderPageTypography>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default ToggleButtonGroupUiObjectDynamicPrice;
