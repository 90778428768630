import Carousel from 'react-material-ui-carousel';
import { Box, CardMedia, Stack, ThemeProvider, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './BusinessPage.scss';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ImageData, MarketPlaceItemData, MarketplaceItemsService } from '../api';
import { stripHtml, useLanguage } from '../common/GeneralUtils';
import { homePageTheme } from './MarketPlaceMain';
import RotatingLoader from '../common/ui/RotatingLoader';
import { VouchersIframe } from './VouchersIframe';
import { whatsAppLinkFormatter } from '../common/whatsAppLinkFormatter';

function Item(props: { item: ImageData }) {
  const { item } = props;
  const isSmallScreen = useMediaQuery('(max-width:768px)');

  if (item.type === 'video') {
    return (
      <div className="video-container">
        <iframe
          className="video-embed"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          title="Speculo Glassblowing Studio @ Caesarea"
          width="640"
          height="360"
          src="https://www.youtube.com/embed/cw7vs23ISuw?autoplay=1&mute=1&showinfo=0&loop=1&playlist=cw7vs23ISuw&controls=0&amp;rel=0&amp;playsinline=1&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fspeculo.life&amp;widgetid=1"
          id="widget2"
        />
      </div>
    );
  }

  return (
    <CardMedia
      component="img"
      width={isSmallScreen ? undefined : '800'}
      height={isSmallScreen ? '200' : '450'}
      image={item.value}
      sx={{ borderRadius: '8px' }}
    />
  );
}

function ImageCarousel(props: { images: ImageData[] }) {
  let { images } = props;
  const isSmallScreen = useMediaQuery('(max-width:768px)');
  if (isSmallScreen) {
    images = images.filter((img) => {
      return img.type === 'image';
    });
  }
  if (images.length > 0) {
    images.shift(); // This will remove the first element which is the first image in thumbnail
  }
  return (
    <Box className="image-carousel-container">
      <Carousel
        NextIcon={<NavigateBefore />}
        PrevIcon={<NavigateNext />}
        swipe
        autoPlay
        interval={5000}
        animation={'slide'}
        cycleNavigation
        indicatorContainerProps={{
          style: {
            position: 'absolute',
            bottom: '6px',
            zIndex: 2,
          },
        }}
        indicatorIconButtonProps={{
          style: {
            color: '#d9d9d9',
            margin: '2px',
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            color: homePageTheme.palette.primary.main,
          },
        }}>
        {images.map((item, i) => (
          <Item key={i} item={item} />
        ))}
      </Carousel>
    </Box>
  );
}

const BusinessPage = () => {
  const { marketPlaceItemId } = useParams();
  const [marketPlaceItem, setMarketPlaceItem] = useState<MarketPlaceItemData | null>(null);
  const [showAllDescription, setShowAllDescription] = useState(false);
  const { t } = useTranslation();
  const language = useLanguage();

  useEffect(() => {
    const fetchMarketplaceItem = async () => {
      if (marketPlaceItemId) {
        const result = await MarketplaceItemsService.getMarketplaceItemById(marketPlaceItemId);
        setMarketPlaceItem(result);
      }
    };

    void fetchMarketplaceItem();
  }, []);

  if (!marketPlaceItem) return <RotatingLoader />;

  return (
    <Box className="business-page">
      <HelmetProvider>
        <Helmet>
          <title>MonkeyBook - {marketPlaceItem.title[language]}</title>
          <meta name="description" content={marketPlaceItem.description[language]} />
        </Helmet>
      </HelmetProvider>
      <ThemeProvider theme={homePageTheme}>
        <ImageCarousel images={marketPlaceItem.images} />
        <Stack direction="row" alignItems="center" marginBottom={4}>
          <Box>
            <img src={marketPlaceItem.logo} className="page-logo" alt={marketPlaceItem.title[language]} />
          </Box>
          <Box>
            <h1>{marketPlaceItem.title[language]}</h1>
            <h2>{marketPlaceItem.subtitle[language]}</h2>
          </Box>
        </Stack>
        <VouchersIframe src={marketPlaceItem.vouchers_link} />

        <Box className="detail-container">
          <div className="title">{t('marketplace.page.about_us')}</div>
          {showAllDescription && (
            <div className="content" dangerouslySetInnerHTML={{ __html: marketPlaceItem.description[language] }} />
          )}
          {!showAllDescription && (
            <div className="content">
              {stripHtml(marketPlaceItem.description[language])}
              <div>
                <a className="read-more-link" onClick={() => setShowAllDescription(true)}>
                  {t('marketplace.page.read_more')}
                </a>
              </div>
            </div>
          )}
        </Box>

        <Box className="detail-container">
          <div className="title">{t('marketplace.page.address')}</div>
          <div className="content">
            <a
              target="_blank"
              href={`https://www.google.com/maps/search/${marketPlaceItem.location.name[language]}`}
              rel="noreferrer">
              {marketPlaceItem.location.name[language]}
            </a>
          </div>
        </Box>

        <Box className="detail-container">
          <div className="title">{t('marketplace.page.website')}</div>
          <div className="content">
            <a target="_blank" href={marketPlaceItem.website} rel="noreferrer">
              {marketPlaceItem.website}
            </a>
          </div>
        </Box>

        <Box className="detail-container">
          <div className="title">{t('marketplace.page.phone')}</div>
          <div className="content">
            <a href={whatsAppLinkFormatter(marketPlaceItem.phone, '')}>{marketPlaceItem.phone}</a>
          </div>
        </Box>

        <Stack direction="row" className="social-media-container">
          {marketPlaceItem.facebook && (
            <a href={marketPlaceItem.facebook}>
              <IconButton className="social-media-button">
                <img src="/images/facebook.png" alt="facebook" />
              </IconButton>
            </a>
          )}
          {marketPlaceItem.instagram && (
            <a href={marketPlaceItem.instagram}>
              <IconButton className="social-media-button">
                <img src="/images/instagram.png" alt="instagram" />
              </IconButton>
            </a>
          )}
          {marketPlaceItem.whatsapp && (
            <a href={whatsAppLinkFormatter(marketPlaceItem.whatsapp, '')}>
              <IconButton className="social-media-button">
                <img src="/images/whatsapp.png" alt="whatsapp" />
              </IconButton>
            </a>
          )}
        </Stack>
      </ThemeProvider>
    </Box>
  );
};

export default BusinessPage;
