import { Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { KeyedMutator } from 'swr/_internal';
import { AccountData, AccountService, UpdateAccountIntegrationsInput } from '../api';
import { FormTextField } from '../experienceSettings/formComponents/FormTextField';
import { UseAuthPermissions } from '../session/UseAuthPermissions';
import { ReactComponent as GTMLogo } from '../images/gtmlogo.svg';
import { ReactComponent as FBLogo } from '../images/metaads.svg';
import { ReactComponent as GA4logo } from '../images/ga4.svg';

type AccountIntegrationsSettingsProps = {
  account: AccountData;
  updateLocalAccount: KeyedMutator<AccountData>;
  setIsUpdatedSnackOpen: (value: boolean) => void;
};
export const AccountIntegrationsSettings = ({
  account,
  updateLocalAccount,
  setIsUpdatedSnackOpen,
}: AccountIntegrationsSettingsProps) => {
  const { t } = useTranslation();
  const [isUpdatingSettings, setIsUpdatingSettings] = React.useState(false);
  const { readOnly } = UseAuthPermissions();

  const initialValues: UpdateAccountIntegrationsInput = {
    gtm_id: account?.marketing_integrations?.gtm_id,
    ga4_id: account?.marketing_integrations?.ga4_id,
    fb_pixel_id: account?.marketing_integrations?.fb_pixel_id,
  };

  const validationSchema = yup.object().shape({
    gtm_id: yup.string().nullable(),
    ga4_id: yup.string().nullable(),
    fb_pixel_id: yup.string().nullable(),
  });

  const onSubmit = async (values: UpdateAccountIntegrationsInput) => {
    setIsUpdatingSettings(true);
    const updatedAccount = await AccountService.integrationsSettings(values);
    await updateLocalAccount(updatedAccount);
    setIsUpdatingSettings(false);
    setIsUpdatedSnackOpen(true);
  };
  const textBoxWidth = 200;
  const formIsDisabled = readOnly || isUpdatingSettings;

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ values, handleChange, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Grid container p={0} flexWrap="nowrap" alignItems="flex-start">
            <Grid container flexDirection="column" gap={2}>
              <Grid item mb={2}>
                <Typography variant="h4">{t('accountIntegrations.title')}</Typography>
              </Grid>
              <Grid container gap={1} flexWrap="nowrap" alignItems="center">
                <GTMLogo width={32} height={32} />
                <FormTextField
                  isFieldChanged={initialValues.gtm_id !== values.gtm_id}
                  width={textBoxWidth}
                  label={t('accountIntegrations.gtmId')}
                  fieldName={`gtm_id`}
                  disabled={formIsDisabled}
                  caption={t('accountIntegrations.gtmIdCaption')}
                />
              </Grid>
              <Grid container gap={1} flexWrap="nowrap">
                <GA4logo width={32} height={32} />
                <FormTextField
                  isFieldChanged={initialValues.ga4_id !== values.ga4_id}
                  width={textBoxWidth}
                  label={t('accountIntegrations.ga4Id')}
                  fieldName={`ga4_id`}
                  disabled={formIsDisabled}
                  caption={t('accountIntegrations.ga4IdCaption')}
                />
              </Grid>
              <Grid container gap={1} flexWrap="nowrap">
                <FBLogo width={32} height={32} />
                <FormTextField
                  isFieldChanged={initialValues.fb_pixel_id !== values.fb_pixel_id}
                  width={textBoxWidth}
                  label={t('accountIntegrations.fbPixel')}
                  fieldName={`fb_pixel_id`}
                  disabled={formIsDisabled}
                  caption={t('accountIntegrations.fbPixelCaption')}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end" mt={2} xs={2}>
              <Button type="submit" variant="contained" disabled={isUpdatingSettings}>
                {t('save')}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
