import React from 'react';
import './WeekendAndIsraeliHolidaysWarning.scss';
import { useTranslation } from 'react-i18next';
import { Alert } from '@mui/material';
import { styled } from '@mui/system';
import PluginUtils, { PluginType } from './PluginUtils';
import { OrderPageTypography } from './themedComponents/OrderPageTypography';

const BorderlessAlert = styled(Alert)({
  border: 'none',
  fontWeight: 'bold',
});
const EscortsWarning = ({ orderPage }: { orderPage: any }) => {
  const { t } = useTranslation();

  if (!PluginUtils.getPluginData(orderPage.plugins, PluginType.REE_ESCORTS)) {
    return null;
  }
  const text = t('order_page.freeEscortsWarning');

  return (
    <BorderlessAlert severity="warning" variant="outlined">
      <OrderPageTypography color="warning" variant={'body1'}>
        {text}
      </OrderPageTypography>
    </BorderlessAlert>
  );
};

export default EscortsWarning;
