import React from 'react';
import './WeekendAndIsraeliHolidaysWarning.scss';
import { useTranslation } from 'react-i18next';
import { Alert } from '@mui/material';
import { styled } from '@mui/system';
import PluginUtils, { PluginType } from './PluginUtils';
import { OrderPageIntent } from './OrderPage';
import { OrderPageTypography } from './themedComponents/OrderPageTypography';

const BorderlessAlert = styled(Alert)({
  border: 'none',
  fontWeight: 'bold',
});
const WeekendAndIsraeliHolidaysWarning = ({
  orderPage,
  shouldHideWeekends,
  orderPageIntent,
}: {
  orderPage: any;
  shouldHideWeekends?: boolean;
  orderPageIntent?: OrderPageIntent;
}) => {
  const { t } = useTranslation();

  if (!PluginUtils.getPluginData(orderPage.plugins, PluginType.WEEKEND_AND_ISRAELI_HOLIDAYS)) {
    return null;
  }
  let text = t('order_page.weekendWarning', {
    percent: (
      (PluginUtils.getPluginData(orderPage.plugins, PluginType.WEEKEND_AND_ISRAELI_HOLIDAYS).multiply - 1) *
      100.0
    ).toFixed(0),
  });
  if (orderPageIntent === OrderPageIntent.REDEEM_GIFT_CARD) {
    if (shouldHideWeekends) {
      text = t('order_page.weekendWarningRedeemGiftCardNotOnWeekends');
    } else {
      text = t('order_page.weekendWarningRedeemGiftCardAlsoOnWeekends');
    }
  }
  return (
    <BorderlessAlert severity="warning" variant="outlined">
      <OrderPageTypography color="warning">{text}</OrderPageTypography>
    </BorderlessAlert>
  );
};

export default WeekendAndIsraeliHolidaysWarning;
