import {
  Button,
  FormControl,
  Grid,
  IconButtonProps,
  MenuItem,
  Paper,
  Select,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import './Calendar.scss';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Today } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { withAuthInfo } from '@propelauth/react';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import IconButton from '@mui/material/IconButton';
import { useParams } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import DatesCarousel from './DatesCarousel';
import UnavailabilityDialog from './dialogs/UnavailabilityDialog/UnavailabilityDialog';
import {
  AccountData,
  CalculatedUserAvailabilityOut,
  EmployeesService,
  EnrichedEventData,
  EventService,
  NoteData,
  OrderData,
  OrderPageTypes,
  OrderService,
  ShiftOut,
  UnavailabilityData,
  UserService,
} from '../api';
import { useCalendar } from './CalendarContext';
import EditOrderDialog from './dialogs/EditOrderDialog/EditOrderDialog';
import ConfirmationDialog from '../common/ui/ConfimationDialog';
import CalendarSpeedDial from './components/CalendarSpeedDial';
import EditEventDialog from './dialogs/EditEventDialog/EditEventDialog';
import { UseAuthPermissions } from '../session/UseAuthPermissions';
import ShiftDialog from './dialogs/ShiftDialog';
import { accountHasEventOrderPages, getOrderPages } from '../common/AccountUtils';
import { useAuth } from '../session/InternalAuthProvider';
import { useShifts } from './swr/useShifts';
import { useUnavailabilities } from './swr/useUnavailabilities';
import { useOrders } from './swr/useOrders';
import { useEvents } from './swr/useEvents';
import { CalendarContent } from './CalendarContent';
import TTLLocalStorage, { WEBOOK_SAVED_CURRENT_DATE } from '../common/TTLLocalStorage';
import Swipe from '../common/Swipe';
import { DailyDashboard } from '../orders/DailyDashboard';
import { useCalendarUserAvailabilities } from './swr/useCalendarUserAvailabilities';
import { UsersMultiSelect } from './components/UsersMultiSelect';
import { UnvailabilityDeletionConfirmation } from './dialogs/UnavailabilityDialog/UnvailabilityDeletionConfirmation';
import { useNotes } from './swr/useNotes';
import NoteDialog from './dialogs/NoteDialog/NoteDialog';

export const DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm';

interface ExpandMoreProps extends IconButtonProps {
  isExpanded: boolean;
  expandUp?: boolean;
}

export const ExpandMore = styled((props: ExpandMoreProps) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme, isExpanded, expandUp }) => ({
  transform: isExpanded ? `rotate(${expandUp ? 180 : 0}deg)` : `rotate(${expandUp ? 0 : 180}deg)`,
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));
export const getToday = () => {
  return moment().format(DATE_FORMAT);
};

export enum CalendarView {
  DAY = 'day',
  THREE_DAY = '3day',
  WEEK = 'week',
  MONTH = 'month',
  CONDENSED = 'condensed',
}

export const mapCalendarViewToNumOfDays = (calendarView: CalendarView | null): number => {
  if (!calendarView) {
    return 7;
  }
  switch (calendarView) {
    case CalendarView.DAY:
      return 1;
    case CalendarView.THREE_DAY:
      return 3;
    case CalendarView.WEEK:
      return 7;
    case CalendarView.MONTH:
      return 30;
    default:
      return 7;
  }
};
const calculateFetchGap = (currentDate: string, calendarView: CalendarView) => {
  const startOfWeek = moment(currentDate).startOf('week');
  const startOfMonth = moment(currentDate).startOf('month');
  switch (calendarView) {
    case CalendarView.DAY:
      return [
        moment(currentDate).subtract(1, 'month').startOf('month'),
        moment(currentDate).add(1, 'month').endOf('month'),
      ];
    case CalendarView.THREE_DAY:
      return [
        moment(currentDate).subtract(1, 'month').startOf('month'),
        moment(currentDate).add(1, 'month').endOf('month'),
      ];
    case CalendarView.WEEK:
      return [
        moment(startOfWeek).subtract(1, 'month').startOf('month'),
        moment(startOfWeek).add(1, 'month').endOf('month'),
      ];
    case CalendarView.MONTH:
      return [
        moment(startOfMonth).subtract(1, 'month').subtract(1, 'week'),
        moment(startOfWeek).add(1, 'month').add(1, 'week'),
      ];
    case CalendarView.CONDENSED:
      return [moment(startOfMonth).subtract(3, 'month').subtract(1, 'week'), moment(startOfWeek).add(12, 'month')];
    default:
      return [moment(currentDate), moment(currentDate).add(1, 'month')];
  }
};
export const getTodayStartsOfWeek = () => {
  return moment().startOf('week').format(DATE_FORMAT);
};

export const getTodayStartsOfMonth = () => {
  return moment().startOf('month').format(DATE_FORMAT);
};
const getInitialCurrentDate = ({
  calendarView,
  savedCurrentDate,
}: {
  calendarView: CalendarView;
  savedCurrentDate?: string | null;
}) => {
  if (savedCurrentDate) {
    try {
      return moment(savedCurrentDate).format(DATE_FORMAT);
    } catch (e: any) {
      console.error('Bad qs');
      return getToday();
    }
  }
  if (calendarView === CalendarView.WEEK) {
    return getTodayStartsOfWeek();
  }
  if (calendarView === CalendarView.MONTH) {
    return getTodayStartsOfMonth();
  }
  return getToday();
};
export type CalendarItems = {
  shifts: Record<string, Array<ShiftOut>>;
  unavailabilities: Record<string, Array<UnavailabilityData>>;
  orders: Record<string, Array<OrderData>>;
  events: Record<string, Array<EnrichedEventData>>;
  calendarUserAvailabilities: Record<string, Array<CalculatedUserAvailabilityOut>>;
  notes: Record<string, Array<NoteData>>;
};
const CalendarWrapper = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const employeesFeatureEnabled = useFeatureFlagEnabled('employees-feature-enabled');

  const { t, i18n } = useTranslation();

  const [isUnavailabilityDialogOpen, setUnavailabilityDialogOpen] = useState(false);
  const [isNoteDialogOpen, setNoteDialogOpen] = useState(false);
  const [isShiftDialogOpen, setShiftDialogOpen] = useState(false);

  const [calendarLoading, setCalendarLoading] = useState(true);

  const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const [isEventBoxOpen, setIsEventBoxOpen] = useState(false);

  const { readOnly } = UseAuthPermissions();
  const previousNextCallbacks = {} as {
    previous: any;
    next: any;
  };
  // @ts-ignore
  const {
    // @ts-ignore
    editOrderDialogState,
    // @ts-ignore
    closeEditOrderDialog,
    // @ts-ignore
    editEventDialogState,
    // @ts-ignore
    openEditOrderDialog,
    // @ts-ignore
    openEditEventDialog,
    // @ts-ignore
    deleteOrderDialogState,
    // @ts-ignore
    closeDeleteOrderDialog,
    // @ts-ignore
    deleteEventDialogState,
    // @ts-ignore
    closeDeleteEventDialog,
    // @ts-ignore
    deleteUnavailabilityDialogState,
    // @ts-ignore
    deleteShiftDialogState,
    // @ts-ignore
    closeDeleteShiftDialog,
    // @ts-ignore
    editShiftDialogState,
    // @ts-ignore
    closeEditShiftDialog,
    // @ts-ignore
    setUsers,
    // @ts-ignore
    users,
    // @ts-ignore
    startDate,
    // @ts-ignore
    endDate,
    // @ts-ignore
    setStartDate,
    // @ts-ignore
    setEndDate,
    // @ts-ignore
    selectedUsersIds,
    // @ts-ignore
    setSelectedUsersIds,
    // @ts-ignore
    shouldBlockSwiping,
  } = useCalendar();
  const { startDate: startDateParam } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const view = urlParams.get('view');
  const [calendarView, setCalendarView] = useState<CalendarView>(
    view
      ? (view as CalendarView)
      : localStorage.getItem('webook-calendarview')
      ? (localStorage.getItem('webook-calendarview') as CalendarView)
      : isSmallScreen
      ? CalendarView.THREE_DAY
      : CalendarView.WEEK,
  );
  const [currentDate, setCurrentDate] = useState(
    getInitialCurrentDate({
      calendarView,
      savedCurrentDate: startDateParam || TTLLocalStorage.getItem(WEBOOK_SAVED_CURRENT_DATE),
    }),
  );
  const setCurrentDateInternal = (newCurrentDate: string) => {
    setCurrentDate(newCurrentDate);
    TTLLocalStorage.setItem(WEBOOK_SAVED_CURRENT_DATE, newCurrentDate, 86400000 * 7);
    const newUrl = `${window.location.origin}/dashboard/calendar/${newCurrentDate}`;
    window.history.pushState(null, '', newUrl);
  };
  const [initialStartDay, initialEndDay] = calculateFetchGap(currentDate, calendarView);

  setStartDate(initialStartDay.format(DATE_FORMAT));
  setEndDate(initialEndDay.format(DATE_FORMAT));

  useEffect(() => {
    // with month view we will be always missing new days on carusel navigation so we should refetch
    if (
      moment(currentDate) <= moment(startDate) ||
      moment(currentDate) >= moment(endDate) ||
      calendarView === CalendarView.MONTH
    ) {
      const [newStartDay, newEndDay] = calculateFetchGap(currentDate, calendarView);
      setStartDate(newStartDay.format(DATE_FORMAT));
      setEndDate(newEndDay.format(DATE_FORMAT));
    }
  }, [currentDate]);

  useEffect(() => {
    const [newStartDay, newEndDay] = calculateFetchGap(currentDate, calendarView);
    setStartDate(newStartDay.format(DATE_FORMAT));
    setEndDate(newEndDay.format(DATE_FORMAT));
  }, [calendarView]);

  const { authState } = useAuth();

  const account = authState.account as unknown as AccountData;
  const hasEventOrderPages = accountHasEventOrderPages(account);

  const { isLoading: isLoadingCalendarUserAvailabilities, items: calendarUserAvailabilities } =
    useCalendarUserAvailabilities({
      startDate,
      endDate,
    });
  const { isLoading: isLoadingShifts, items: shifts, deleteItem: deleteShift } = useShifts({ startDate, endDate });
  const {
    isLoading: isLoadingUnavailabilities,
    items: unavailabilities,
    deleteItem: deleteUnavailability,
    reFetch: reFetchUnavailabilities,
  } = useUnavailabilities({ startDate, endDate });

  const {
    isLoading: isLoadingNotes,
    items: notes,
    deleteItem: deleteNote,
    reFetch: reFetchNotes,
  } = useNotes({ startDate, endDate });
  const {
    isLoading: isLoadingOrders,
    items: orders,
    deleteItem: deleteOrder,
    addItem: addOrder,
    updateItem: updateOrder,
  } = useOrders({ startDate, endDate });

  const {
    isLoading: isLoadingEvents,
    items: events,
    deleteItem: deleteEvent,
    addItems: addEvents,
    updateItem: updateEvent,
  } = useEvents({ startDate, endDate });

  useEffect(() => {
    setCalendarLoading(
      isLoadingShifts ||
        isLoadingUnavailabilities ||
        isLoadingOrders ||
        isLoadingNotes ||
        isLoadingEvents ||
        isLoadingCalendarUserAvailabilities,
    );
  }, [
    isLoadingShifts,
    isLoadingUnavailabilities,
    isLoadingOrders,
    isLoadingNotes,
    isLoadingEvents,
    isLoadingCalendarUserAvailabilities,
  ]);

  const calendarItems: CalendarItems = {
    shifts,
    unavailabilities,
    orders,
    events,
    calendarUserAvailabilities,
    notes,
  };
  const [isDailyDashboardExpanded, setIsDailyDashboardExpanded] = React.useState(
    localStorage.getItem('webook-isDailyDashboardExpended') === 'true',
  );
  const handleExpandClick = () => {
    localStorage.setItem('webook-isDailyDashboardExpended', JSON.stringify(!isDailyDashboardExpanded));
    setIsDailyDashboardExpanded(!isDailyDashboardExpanded);
  };
  useEffect(() => {
    localStorage.setItem('webook-calendarview', calendarView || '');
  }, [calendarView]);

  useEffect(() => {
    const fetchUsers = async () => {
      const users = await UserService.getUsers();
      if (setUsers) {
        setUsers(users);
      }
    };
    void fetchUsers();
  }, []);

  const setShiftOpenInternal = (val: any) => {
    setShiftDialogOpen(val);
    closeEditShiftDialog();
  };
  // TODO find better solution. this is used to prevent swipes
  const isAnyDialogOpen = () => {
    return (
      editOrderDialogState.isOpen ||
      editEventDialogState.isOpen ||
      deleteOrderDialogState.isOpen ||
      deleteEventDialogState.isOpen ||
      deleteUnavailabilityDialogState.isOpen ||
      isUnavailabilityDialogOpen ||
      isEventBoxOpen
    );
  };

  const onSwipeLeft = () => {
    if (isAnyDialogOpen() || calendarView === CalendarView.CONDENSED) {
      return;
    }
    if (i18n.dir() === 'rtl') {
      previousNextCallbacks.previous();
    } else {
      previousNextCallbacks.next();
    }
  };

  const onSwipeRight = () => {
    if (isAnyDialogOpen() || calendarView === CalendarView.CONDENSED) {
      return;
    }
    if (i18n.dir() === 'rtl') {
      previousNextCallbacks.next();
    } else {
      previousNextCallbacks.previous();
    }
  };
  const onTodayClicked = () => {
    setCurrentDate(getInitialCurrentDate({ calendarView }));
    const newUrl = `${window.location.origin}/dashboard/calendar/`;
    window.history.pushState(null, '', newUrl);
    TTLLocalStorage.removeItem(WEBOOK_SAVED_CURRENT_DATE);
  };
  const orderPages = getOrderPages(account);
  const hasOnlyOneOrderPageAndItsEvents =
    orderPages.length === 1 && orderPages[0].order_page_type === OrderPageTypes.EVENT;

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Monkeybook - {t('main_sidebar.calendar')}</title>
        </Helmet>
      </HelmetProvider>

      <UnavailabilityDialog
        open={isUnavailabilityDialogOpen}
        setOpen={setUnavailabilityDialogOpen}
        calendarEndDate={endDate}
        calendarStartDate={startDate}
      />
      <NoteDialog
        open={isNoteDialogOpen}
        setOpen={setNoteDialogOpen}
        calendarEndDate={endDate}
        calendarStartDate={startDate}
      />
      <ShiftDialog
        open={isShiftDialogOpen || editShiftDialogState?.isOpen}
        setOpen={setShiftOpenInternal}
        shift={editShiftDialogState?.shift}
        startDate={startDate}
        endDate={endDate}
        createNewShiftPayload={editShiftDialogState.createNewShiftPayload}
      />

      <EditOrderDialog
        editOrderDialogState={editOrderDialogState}
        closeEditOrderDialog={closeEditOrderDialog}
        addOrder={(orderToUpsert: OrderData) => addOrder(orderToUpsert)}
        updateOrder={updateOrder}
      />
      <EditEventDialog
        addEvents={(events: EnrichedEventData[]) => addEvents(events)}
        updateEvent={(event: EnrichedEventData) => updateEvent(event)}
      />

      <ConfirmationDialog
        title={t('calendar.delete_order.title')}
        content={t('calendar.delete_order.description')}
        isOpen={deleteOrderDialogState?.isOpen}
        onClose={async (actionConfirmed) => {
          if (actionConfirmed) {
            await OrderService.deleteOrder(deleteOrderDialogState.order.id);
            if (!deleteOrderDialogState.order.parent_id) {
              deleteOrder(deleteOrderDialogState.order);
            }

            if (deleteOrderDialogState.onSubmitCallback) {
              deleteOrderDialogState.onSubmitCallback();
            }
            closeDeleteOrderDialog();
          } else {
            closeDeleteOrderDialog();
          }
        }}
      />

      <ConfirmationDialog
        title={t('calendar.delete_event.title')}
        content={t('calendar.delete_event.description')}
        isOpen={deleteEventDialogState?.isOpen}
        onClose={async (actionConfirmed) => {
          if (actionConfirmed) {
            await EventService.deleteEvent(deleteEventDialogState?.event.id);
            deleteEvent(deleteEventDialogState?.event);
          }
          closeDeleteEventDialog();
        }}
      />
      <UnvailabilityDeletionConfirmation
        reFetchUnavailabilities={reFetchUnavailabilities}
        deleteUnavailability={deleteUnavailability}
      />

      <ConfirmationDialog
        title={t('calendar.delete_shift.title')}
        content={t('calendar.delete_shift.description')}
        isOpen={deleteShiftDialogState?.isOpen}
        onClose={async (actionConfirmed) => {
          closeDeleteShiftDialog();
          if (actionConfirmed) {
            const shiftToDelete = deleteShiftDialogState.shift;
            await EmployeesService.deleteShift(shiftToDelete.id);
            deleteShift(shiftToDelete);
          }
        }}
      />
      <Grid sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }} gap={1}>
        {isDailyDashboardExpanded && (
          <Grid p={isSmallScreen ? 2 : undefined}>
            <DailyDashboard />
          </Grid>
        )}

        <Paper className="calendar" sx={{ overflowX: 'auto', flex: 1 }}>
          <Grid container className="control-row">
            <Grid
              container
              className="control-row"
              alignItems="flex-start"
              gap={1}
              xs={isSmallScreen ? 12 : 6}
              mt={isSmallScreen ? 1 : undefined}>
              <Grid>
                <ExpandMore
                  expandUp
                  isExpanded={isDailyDashboardExpanded}
                  onClick={handleExpandClick}
                  aria-expanded={isDailyDashboardExpanded}
                  aria-label="show more">
                  <ExpandMoreIcon />
                </ExpandMore>
              </Grid>

              <FormControl>
                <Select
                  style={{ height: '39px', width: '90px' }}
                  className="view-select"
                  value={calendarView}
                  onChange={(event) => {
                    setCalendarView(event.target.value as CalendarView);
                    if (event.target.value === 'week') {
                      setCurrentDateInternal(moment(currentDate).startOf('week').format(DATE_FORMAT));
                    }
                    if (event.target.value === 'month') {
                      setCurrentDateInternal(moment(currentDate).startOf('month').format(DATE_FORMAT));
                    }
                  }}>
                  <MenuItem value={CalendarView.DAY}>{t('calendar.view.day')}</MenuItem>
                  <MenuItem value={CalendarView.THREE_DAY}>{t('calendar.view.3day')}</MenuItem>
                  <MenuItem value={CalendarView.WEEK}>{t('calendar.view.week')}</MenuItem>
                  <MenuItem value={CalendarView.MONTH}>{t('calendar.view.month')}</MenuItem>
                  {hasOnlyOneOrderPageAndItsEvents ? (
                    <MenuItem disabled={!hasOnlyOneOrderPageAndItsEvents} value={CalendarView.CONDENSED}>
                      {t('calendar.view.condensed')}
                    </MenuItem>
                  ) : null}
                </Select>
              </FormControl>

              {calendarView === CalendarView.CONDENSED ? null : (
                <>
                  <DatesCarousel
                    currentDate={currentDate}
                    setCurrentDate={setCurrentDateInternal}
                    calendarView={calendarView}
                    previousNextCallbacks={previousNextCallbacks}
                  />

                  <IconButton
                    onClick={onTodayClicked}
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                    }}>
                    <Today />
                  </IconButton>
                </>
              )}
              {employeesFeatureEnabled && (
                <UsersMultiSelect
                  size="small"
                  users={users}
                  selectedUsersIds={selectedUsersIds}
                  setSelectedUsersIds={setSelectedUsersIds}
                />
              )}
            </Grid>
            {!isMediumScreen && (
              <Grid container xs={6} gap={1} justifyContent="flex-end" alignItems="flex-start">
                <Tooltip title={readOnly ? t('noPermissions') : undefined}>
                  <Button
                    disabled={readOnly}
                    variant="outlined"
                    sx={{ textTransform: 'none' }}
                    onClick={() => setNoteDialogOpen(true)}>
                    {t('calendar.add_note')}
                  </Button>
                </Tooltip>
                {employeesFeatureEnabled && (
                  <Button variant="outlined" sx={{ textTransform: 'none' }} onClick={() => setShiftDialogOpen(true)}>
                    {account.shifts_as_employee_unavailability
                      ? t('calendar.add_employee_unavliability')
                      : t('calendar.add_shift')}
                  </Button>
                )}

                <Tooltip title={readOnly ? t('noPermissions') : undefined}>
                  <Button
                    disabled={readOnly}
                    variant="outlined"
                    sx={{ textTransform: 'none' }}
                    onClick={() => setUnavailabilityDialogOpen(true)}>
                    {t('calendar.add_unavailability')}
                  </Button>
                </Tooltip>

                {hasEventOrderPages && (
                  <Tooltip title={readOnly ? t('noPermissions') : undefined}>
                    <Button
                      disabled={readOnly}
                      variant="outlined"
                      sx={{ textTransform: 'none' }}
                      onClick={() => openEditEventDialog()}>
                      {t('add_slots')}
                    </Button>
                  </Tooltip>
                )}

                <Tooltip title={readOnly ? t('noPermissions') : undefined}>
                  <Button
                    disabled={readOnly}
                    variant="contained"
                    sx={{ textTransform: 'none' }}
                    onClick={() => openEditOrderDialog()}>
                    {t('add_order')}
                  </Button>
                </Tooltip>
              </Grid>
            )}
          </Grid>
          <Swipe
            sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}
            onSwipeRight={onSwipeRight}
            onSwipeLeft={onSwipeLeft}
            shouldBlockSwiping={shouldBlockSwiping}>
            <Grid
              sx={{
                maxHeight: 'calc(100vh - 140px)',
                overflowX: 'auto',
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
              }}>
              <CalendarContent
                calendarLoading={calendarLoading}
                calendarView={calendarView}
                calendarItems={calendarItems}
                currentDate={currentDate}
                isEventBoxOpen={isEventBoxOpen}
                setIsEventBoxOpen={setIsEventBoxOpen}
              />
            </Grid>
          </Swipe>

          {isMediumScreen && (
            <CalendarSpeedDial
              open={speedDialOpen}
              setOpen={setSpeedDialOpen}
              setShiftDialogOpen={setShiftDialogOpen}
              setUnavailabilityDialogOpen={setUnavailabilityDialogOpen}
              setNoteDialogOpen={setNoteDialogOpen}
            />
          )}
        </Paper>
      </Grid>
    </>
  );
};

export default withAuthInfo(CalendarWrapper);
