import { createContext, useContext, useState } from 'react';
import moment from 'moment';
import { WidgetThemeProvider } from '../order_page/theme/Theme';
import { DATE_FORMAT } from './CalendarWrapper';

const initialState = {
  calendarLoading: true,
};
const userColorPallete = [
  '#609608', // Vibrant Red
  '#1932b0', // Vibrant Green
  '#a81875', // Vibrant Blue
  '#6e560a', // Vibrant Pink
  '#071d64', // Vibrant Cyan
  '#FFC233', // Vibrant Yellow
  '#A833FF', // Vibrant Purple
  '#FF8333', // Vibrant Orange
  '#1e4604', // Vibrant Mint
  '#FF338F', // Vibrant Magenta
  '#15330f', // Vibrant Lime
  '#8A33FF', // Vibrant Violet
  '#FF5733', // Vibrant Coral
  '#33A8FF', // Vibrant Sky Blue
  '#ff33b8', // Vibrant Lemon
  '#FF5733', // Vibrant Sunset
  '#57FFA8', // Vibrant Teal
  '#FF338F', // Vibrant Rose
  '#5733FF', // Vibrant Indigo
  '#FFA833', // Vibrant Apricot
];

const CalendarContext = createContext(initialState);

export const useCalendar = () => useContext(CalendarContext);

export const CalendarProvider = ({ children }) => {
  const [users, setUsers] = useState(null);
  const [userColors, setUserColors] = useState([]);
  const [selectedUsersIds, setSelectedUsersIds] = useState([]);

  const [startDate, setStartDate] = useState(moment().format(DATE_FORMAT));
  const [endDate, setEndDate] = useState(moment().format(DATE_FORMAT));
  const [shouldBlockSwiping, setShouldBlockSwiping] = useState(false);
  const [editOrderDialogState, setEditOrderDialogState] = useState({
    isOpen: false,
  });
  const [editEventDialogState, setEditEventDialogState] = useState({
    isOpen: false,
  });
  const [deleteOrderDialogState, setDeleteOrderDialogState] = useState({
    isOpen: false,
  });
  const [deleteEventDialogState, setDeleteEventDialogState] = useState({
    isOpen: false,
  });

  const [deleteUnavailabilityDialogState, setDeleteUnavailabilityDialogState] = useState({ isOpen: false });
  const [deleteNoteDialogState, setDeleteNoteDialogState] = useState({ isOpen: false });

  const [deleteShiftDialogState, setDeleteShiftDialogState] = useState({ isOpen: false });
  const [editShiftDialogState, setEditShiftDialogState] = useState({ isOpen: false });
  // to make the event popper open after reloading calendar. not very good design.

  const openEditOrderDialog = (
    order,
    orderPageId,
    parentId,
    parent,
    voucherToRedeem,
    createVoucher,
    voucherToEdit,
    cloneOrder,
  ) => {
    setEditOrderDialogState({
      ...editOrderDialogState,
      isOpen: true,
      order,
      orderPageId,
      parentId,
      parent,
      voucherToRedeem,
      createVoucher,
      voucherToEdit,
      cloneOrder,
    });
  };

  const closeEditOrderDialog = () => {
    setEditOrderDialogState({
      ...editOrderDialogState,
      isOpen: false,
    });
  };

  const openEditEventDialog = (event, orderPageId) => {
    setEditEventDialogState({
      ...editEventDialogState,
      isOpen: true,
      event,
      orderPageId,
    });
  };

  const closeEditEventDialog = () => {
    setEditEventDialogState({
      ...editEventDialogState,
      isOpen: false,
    });
  };

  const openDeleteOrderDialog = (order, onSubmitCallback) => {
    setDeleteOrderDialogState({
      ...deleteOrderDialogState,
      isOpen: true,
      order,
      onSubmitCallback,
    });
  };

  const closeDeleteOrderDialog = () => {
    setDeleteOrderDialogState({
      ...deleteOrderDialogState,
      isOpen: false,
    });
  };

  const openDeleteEventDialog = (event) => {
    setDeleteEventDialogState({
      ...deleteEventDialogState,
      isOpen: true,
      event,
    });
  };

  const closeDeleteEventDialog = () => {
    setDeleteEventDialogState({
      ...deleteEventDialogState,
      isOpen: false,
    });
  };

  const openDeleteUnavailabilityDialog = (unavailability) => {
    setDeleteUnavailabilityDialogState({
      ...deleteUnavailabilityDialogState,
      isOpen: true,
      unavailability,
    });
  };

  const closeDeleteNoteDialog = () => {
    setDeleteNoteDialogState({
      ...deleteNoteDialogState,
      isOpen: false,
    });
  };
  const openDeleteNoteDialog = (note) => {
    setDeleteNoteDialogState({
      ...deleteNoteDialogState,
      isOpen: true,
      note,
    });
  };

  const closeDeleteUnavailabilityDialog = () => {
    setDeleteUnavailabilityDialogState({
      ...deleteUnavailabilityDialogState,
      isOpen: false,
    });
  };

  const openDeleteShiftDialog = (shift) => {
    setDeleteShiftDialogState({
      ...deleteShiftDialogState,
      isOpen: true,
      shift,
    });
  };

  const closeDeleteShiftDialog = () => {
    setDeleteShiftDialogState({
      ...deleteShiftDialogState,
      isOpen: false,
    });
  };
  const openEditShiftDialog = (shift, createNewShiftPayload) => {
    setEditShiftDialogState({
      ...editShiftDialogState,
      isOpen: true,
      shift,
      createNewShiftPayload,
    });
  };

  const closeEditShiftDialog = () => {
    setEditShiftDialogState({
      ...editShiftDialogState,
      shift: undefined,
      isOpen: false,
    });
  };
  const setUsersInternal = (users) => {
    setUsers(users);
    const userColorMap = {};
    users.forEach((user, index) => {
      userColorMap[user.user_id] = userColorPallete[index % userColorPallete.length];
    });
    setUserColors(userColorMap);
  };
  return (
    <WidgetThemeProvider>
      <CalendarContext.Provider
        value={{
          openEditOrderDialog,
          closeEditOrderDialog,
          editOrderDialogState,

          openEditEventDialog,
          closeEditEventDialog,
          editEventDialogState,

          openDeleteOrderDialog,
          closeDeleteOrderDialog,
          deleteOrderDialogState,

          openDeleteEventDialog,
          closeDeleteEventDialog,
          deleteEventDialogState,

          openDeleteUnavailabilityDialog,
          closeDeleteUnavailabilityDialog,
          deleteUnavailabilityDialogState,

          openDeleteShiftDialog,
          closeDeleteShiftDialog,
          deleteShiftDialogState,

          openEditShiftDialog,
          closeEditShiftDialog,
          editShiftDialogState,

          openDeleteNoteDialog,
          closeDeleteNoteDialog,
          deleteNoteDialogState,

          users,
          setUsers: setUsersInternal,

          startDate,
          endDate,
          setStartDate,
          setEndDate,

          userColors,
          selectedUsersIds,
          setSelectedUsersIds,
          shouldBlockSwiping,
          setShouldBlockSwiping,
        }}>
        {children}
      </CalendarContext.Provider>
    </WidgetThemeProvider>
  );
};
