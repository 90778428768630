import React from 'react';
import { FormikHandlers } from 'formik';
import { OrderPageTextField } from '../../themedComponents/OrderPageTextField';
import { CustomPersonalDetailData } from '../../../api';
import { useLanguage } from '../../../common/GeneralUtils';
import { PersonalDetailsValues } from '../PersonalDetailsStep';

type RenderMultilineDetailProps = {
  customPersonalDetail: CustomPersonalDetailData;
  handleChange: FormikHandlers['handleChange'];
  values: PersonalDetailsValues;
};
export const RenderMultilineDetail = ({ customPersonalDetail, handleChange, values }: RenderMultilineDetailProps) => {
  const language = useLanguage();
  // @ts-ignore
  const value = values.custom_personal_details[customPersonalDetail.id];
  return (
    <OrderPageTextField
      fullWidth
      className="text-field"
      id={customPersonalDetail.id}
      name={`custom_personal_details.${customPersonalDetail.id}`}
      label={customPersonalDetail.label[language]}
      value={value}
      onChange={handleChange}
      multiline
    />
  );
};
