/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum LocationsStatus {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
}
