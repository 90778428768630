import React from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Paper, useTheme } from '@mui/material';
import uuid from 'react-uuid';
import { styled } from '@mui/system';
import { CustomPersonalDetailData, LabelData } from '../../../api';
import { PersonalDetailsValues } from '../PersonalDetailsStep';
import { OrderPageTypography } from '../../themedComponents/OrderPageTypography';
import { OrderPageSelect } from '../../themedComponents/OrderPageSelect';
import { useLanguage } from '../../../common/GeneralUtils';

type RenderDropDownDetailProps = {
  customPersonalDetail: CustomPersonalDetailData;
  setFieldValue: (field: string, value: any) => void;
  values: PersonalDetailsValues;
};

export const FormStyledPaper = styled(Paper)`
  && {
    background-color: ${(props: any) => props.backgroundColor || '#f0f0f0'}; // dynamic background color
  }
`;

export const FormStyledMenuItem = styled(MenuItem)(
  ({ textColor, backgroundColor }: { textColor?: string; backgroundColor: string }) => ({
    color: textColor,
    background: backgroundColor,
    backgroundColor,
    '&:hover': {
      backgroundColor,
    },
  }),
);

export const RenderDropDownDetail = ({ customPersonalDetail, setFieldValue, values }: RenderDropDownDetailProps) => {
  const language = useLanguage();
  const theme = useTheme();
  const textColor = theme.customTheme.palette.text.primary;
  const { background } = theme.customTheme.palette;

  // @ts-ignore
  const value = values.custom_personal_details?.[customPersonalDetail.id];
  const detailId = customPersonalDetail.id;
  const id = `RenderDropDownDetail-${uuid()}`;
  return (
    <Grid container flexDirection="column" item>
      <FormControl fullWidth variant="standard">
        <InputLabel id={id}>
          <OrderPageTypography>{customPersonalDetail.label[language]}</OrderPageTypography>
        </InputLabel>
        <OrderPageSelect
          MenuProps={{
            PaperProps: {
              component: FormStyledPaper,
              backgroundColor: background,
            },
          }}
          labelId={id}
          id={id}
          value={value}
          onChange={(e) => setFieldValue(`custom_personal_details.${detailId}`, e.target.value)}
          renderValue={(v: any) => <OrderPageTypography>{v}</OrderPageTypography>}>
          {customPersonalDetail.drop_down_options?.map((item: LabelData) => (
            <FormStyledMenuItem
              key={`MenuItem-${item[language]}`}
              value={item[language]}
              textColor={textColor}
              backgroundColor={background}>
              {item[language]}
            </FormStyledMenuItem>
          ))}
        </OrderPageSelect>
      </FormControl>
    </Grid>
  );
};
