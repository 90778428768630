import { Grid } from '@mui/material';
import './UnavailabilityBox.scss';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { NoteData } from '../../api';
import { useCalendar } from '../CalendarContext';
import NoteDialog from '../dialogs/NoteDialog/NoteDialog';

type NoteBoxProps = {
  note: NoteData;
  viewType?: 'month' | 'day';
  css?: any;
};
const NoteBox = ({ note, viewType, css }: NoteBoxProps) => {
  const [isNoteDialogOpen, setNoteDialogOpen] = useState(false);
  const {
    // @ts-ignore
    startDate,
    // @ts-ignore
    endDate,
    // @ts-ignore
  } = useCalendar();
  const { t } = useTranslation();

  const getTimeText = () => {
    if (note.all_day) {
      return 'All day';
    }
    return `${moment.unix(note.start_time).tz('utc').format('HH:mm')} - ${moment
      .unix(note.end_time)
      .tz('utc')
      .format('HH:mm')}`;
  };

  let dynamicStyles = null;
  if (css) {
    dynamicStyles = {
      width: `calc(${css.width}% - 2px)`,
      left: `${css.left}%`,
      top: `${css.top - 1}px`,
      height: `${css.height - 5}px`,
      position: 'absolute',
    };
  }

  return (
    <>
      <Grid
        onClick={() => setNoteDialogOpen(true)}
        className={`unavailability-box flex-1${viewType === 'month' ? ' month-unavailability-box' : ''}`}
        sx={dynamicStyles}
        style={{ backgroundColor: 'white', color: 'black', borderColor: 'black' }}>
        <Grid className="text-center unavailability-box-inner">
          {viewType === 'month' && (
            <p dir="ltr">
              <strong>{getTimeText()}</strong>
            </p>
          )}
          <p>
            <span>{note.description ? note.description : t('calendar.note')}</span>
          </p>
        </Grid>
      </Grid>
      <NoteDialog
        note={note}
        open={isNoteDialogOpen}
        setOpen={setNoteDialogOpen}
        calendarEndDate={endDate}
        calendarStartDate={startDate}
      />
    </>
  );
};

export default NoteBox;
