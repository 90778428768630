import { Button, Checkbox, Divider, FormControlLabel, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import useSWR from 'swr';
import { CodeResponse, useGoogleLogin } from '@react-oauth/google';
import { useAuthInfo } from '@propelauth/react';
import { Form, Formik } from 'formik';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import RotatingLoader from '../common/ui/RotatingLoader';
import { AccountData, UserPreferencesService, UserPreferencesUpdateDTO } from '../api';
import { WorkingDays } from '../experienceSettings/workingSchedule/WorkingDays';
import { OrderPagesMultiSelect } from './OrderPagesMultiSelect';
import { useAuth } from '../session/InternalAuthProvider';

export const UserSettingsPage = () => {
  // adding token to swr key for disconnects
  const { user } = useAuthInfo();
  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  const { t } = useTranslation();
  const [isUpdatingSettings, setIsUpdatingSettings] = useState(false);
  const {
    data: userPreferences,
    mutate,
    isLoading,
  } = useSWR({ opId: 'getUserPreferences' }, () => UserPreferencesService.getUserPreferences(), {
    refreshInterval: 20000,
  });
  const initialValues: UserPreferencesUpdateDTO = {
    should_send_shift_notifications: userPreferences?.should_send_shift_notifications || false,
    should_send_workshop_assignment_notifications:
      userPreferences?.should_send_workshop_assignment_notifications || false,
    google_integration_enabled: userPreferences?.google_integration_enabled || false,
    working_days: userPreferences?.working_days,
    qualified_order_pages: userPreferences?.qualified_order_pages || [],
  };
  const validationSchema = yup.object().shape({
    should_send_shift_notifications: yup.boolean().required(),
    should_send_workshop_assignment_notifications: yup.boolean().required(),
    google_integration_enabled: yup.boolean(),
  });

  const onSubmit = async (values: UserPreferencesUpdateDTO) => {
    setIsUpdatingSettings(true);
    await UserPreferencesService.editUserPreferences(values);
    // @ts-ignore
    await mutate({ ...userPreferences, ...values });
    setIsUpdatingSettings(false);
  };

  const onLoginSuccess = async (codeResponse: Omit<CodeResponse, 'error' | 'error_description' | 'error_uri'>) => {
    setIsUpdatingSettings(true);
    const newSettings = await UserPreferencesService.gcalConnect({
      auth_code: codeResponse.code,
    });
    //   @ts-ignore
    await mutate({ ...userPreferences, ...newSettings });
    setIsUpdatingSettings(false);
  };
  const disconnectFromGoogle = async () => {
    setIsUpdatingSettings(true);
    const newSettings = {
      google_integration_enabled: false,
    };
    // @ts-ignore
    await UserPreferencesService.editUserPreferences({ ...userPreferences, ...newSettings });
    //   @ts-ignore
    await mutate({ ...userPreferences, ...newSettings });
    setIsUpdatingSettings(false);
  };

  const login = useGoogleLogin({
    onSuccess: onLoginSuccess,
    scope: 'https://www.googleapis.com/auth/calendar',
    flow: 'auth-code',
    include_granted_scopes: false,
  });
  if (isLoading || isUpdatingSettings) {
    return (
      <Grid
        container
        sx={{
          minHeight: '200px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <RotatingLoader />
      </Grid>
    );
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ errors, touched, values, handleSubmit, handleBlur, handleChange, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <Grid container p={5} flexDirection="column">
            <Grid item mb={2}>
              <Typography variant="h5" fontWeight={700}>
                {t('userSettings.sync')}
              </Typography>
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    name="should_send_shift_notifications"
                    onBlur={handleBlur}
                    checked={values.should_send_shift_notifications}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={t('userSettings.sendShiftNotifications')}
              />
              {touched.should_send_shift_notifications && errors.should_send_shift_notifications && (
                <Typography color="error">{errors.should_send_shift_notifications}</Typography>
              )}
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    name="should_send_workshop_assignment_notifications"
                    onBlur={handleBlur}
                    checked={values.should_send_workshop_assignment_notifications}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={t('userSettings.sendWorkshopNotifications')}
              />
              {touched.should_send_workshop_assignment_notifications &&
                errors.should_send_workshop_assignment_notifications && (
                  <Typography color="error">{errors.should_send_workshop_assignment_notifications}</Typography>
                )}
            </Grid>
            <Grid container flexDirection="column" gap={2} mt={4}>
              <Typography fontWeight={700}>{t('userSettings.whereToSync')}</Typography>

              {userPreferences?.google_integration_enabled ? (
                <Grid container alignItems="center" gap={2}>
                  <img src={'/images/gcallogo.png'} width="24px" />
                  <Typography>
                    {t('userSettings.gcalSyncEmail', {
                      userEmail: userPreferences.gcal_synced_email,
                    })}
                  </Typography>
                  <Grid item mx={4}>
                    <Button variant="outlined" onClick={disconnectFromGoogle}>
                      {t('userSettings.disconnect')}
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Grid container flexDirection="column" gap={2}>
                  <Typography>
                    {t('userSettings.userEmailSync', {
                      userEmail: user?.email,
                    })}
                  </Typography>
                  <Typography>{t('userSettings.googleSync')}:</Typography>
                  <Grid item maxWidth={'250px'} mt={-1}>
                    <Button onClick={login} variant="outlined" style={{ borderRadius: '100px', borderColor: 'black' }}>
                      <Grid container dir="ltr" gap={1} mx="8px" my="4px">
                        <img src={'/images/gcallogo.png'} style={{ width: '24px' }} />
                        <Typography variant="button" style={{ textTransform: 'none' }} color="black">
                          Connect Google Calendar
                        </Typography>
                      </Grid>
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Divider sx={{ marginY: 4 }} />
            <WorkingDays
              readOnly={false}
              initialWorkingDays={initialValues.working_days}
              workingDays={values.working_days}
              setFieldValue={setFieldValue}
              isSubmitting={isUpdatingSettings}
              hideFixedOption
              title={t('userAvailability.standardSchedule')}
            />
            <Divider sx={{ marginY: 4 }} />
            <Typography variant="h5" fontWeight={700}>
              {t('userSettings.qualification')}
            </Typography>
            <OrderPagesMultiSelect
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              orderPagesFieldName="qualified_order_pages"
              account={account}
            />
            <Grid container justifyContent="flex-end" mt={2}>
              <Button type="submit" variant="contained" disabled={isUpdatingSettings}>
                {t('save')}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
