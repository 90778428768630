import './PaymentStep.scss';
import React, { useEffect } from 'react';
import 'moment-timezone';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { StripePaymentFrame } from './payments/StripePaymentFrame';
import { OrderPageTypography } from '../themedComponents/OrderPageTypography';

const urlHeightResolver = (paymentUrl: string, isSmallScreen: boolean) => {
  if (paymentUrl.includes('payplus')) {
    return '710px';
  }
  if (paymentUrl.includes('payme')) {
    return isSmallScreen ? '700px' : '440px';
  }
  if (paymentUrl.includes('meshulam')) {
    return isSmallScreen ? '363px' : '480px';
  }
  if (paymentUrl.includes('tranzila')) {
    return isSmallScreen ? '520px' : '500px';
  }

  return '700px';
};
const PaymentIframe = ({
  paymentUrl,
  stripe_integration_publishable_key,
  stripeAccountId,
}: {
  paymentUrl: string;
  stripeAccountId?: string;
  stripe_integration_publishable_key?: string;
}) => {
  const paymentsTestPostToIframe = useFeatureFlagEnabled('payments-test-post-to-iframe');
  const paymentsTestRedirectToIframe = useFeatureFlagEnabled('payments-test-redirect-to-iframe');

  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  useEffect(() => {
    // Payplus integration doesn't seem to remove it in orderpagecompleted is it moves us out of the iframe.
    // removing it here means that refreshing the page is going to forget the order, which is fine.
    // updaate 2024-5-21 tested and it doesn't seem to issue so commented out the code for now.
    if (paymentUrl?.includes('payplus')) {
      // TTLLocalStorage.removeByPrefix('order-page-state-');
    }
  }, []);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  if (paymentUrl.includes('https://secure.meshulam.co.il/credit-checkout-form')) {
    if (paymentsTestPostToIframe) {
      const message = { paymentUrl };
      window.parent.postMessage(message, '*');
      return <Grid>TESTING RENDERING FROM OUTSIDE OF IFRAME</Grid>;
    }
    if (paymentsTestRedirectToIframe) {
      window.location.href = paymentUrl;
      return <Grid>TESTING REDIRECT IFRAME</Grid>;
    }
  }
  if (stripe_integration_publishable_key) {
    return (
      <StripePaymentFrame
        publishableKey={stripe_integration_publishable_key}
        clientSecret={paymentUrl}
        stripeAccountId={stripeAccountId}
      />
    );
  }
  return (
    <Grid container flexDirection="column">
      {paymentUrl.includes('tranzila') && (
        <Grid item mt={3}>
          <OrderPageTypography bold>{t('paymentIframe.noAmericanError')}</OrderPageTypography>
        </Grid>
      )}
      <iframe
        style={{ borderRadius: '8px', border: `1px solid ${theme.customTheme.palette.border}` }}
        height={urlHeightResolver(paymentUrl, isSmallScreen)}
        title="payment-iframe"
        src={paymentUrl}
        className="payment-iframe"
      />
    </Grid>
  );
};

export default PaymentIframe;
