import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { Form, Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { omit } from 'lodash';
import { usePostHog } from 'posthog-js/react';
import { KeyedMutator } from 'swr/_internal';
import Typography from '@mui/material/Typography';
import { UploadWidgetResult } from '@bytescale/upload-widget';
import { RemoveCircleOutline } from '@mui/icons-material';
import { AccountData, AccountService, OrderPageData, OrderPageTypes } from '../../../api';
import { OrderPageUpdate } from '../../OrderPageSettingsPage';
import { UseAuthPermissions } from '../../../session/UseAuthPermissions';
import { FormFieldColorPicker } from '../../formComponents/FormFieldColorPicker';
import { FormTextField } from '../../formComponents/FormTextField';
import { ImageUploadWidget } from '../../../emailTemplates/templateEditor/components/bytescale/ImageUploadWidget';
import { LocationPicker } from './LocationPicker';

type SettingsSectionProps = {
  orderPage: OrderPageData;
  orderPageInitialValues: OrderPageUpdate;
  updateLocalAccount: KeyedMutator<AccountData>;
  setIsUpdatedSnackOpen: (value: boolean) => void;
};

export type SettingsFormValues = {
  color: string;
  duration: number;
  min_date_in_days?: number;
  max_date_in_days: number;
  after_buffer_minutes: number;
  photo?: string;
  location_id?: string;
};

export const SettingsSection = ({
  orderPage,
  orderPageInitialValues,
  updateLocalAccount,
  setIsUpdatedSnackOpen,
}: SettingsSectionProps) => {
  const { t } = useTranslation();
  const { readOnly } = UseAuthPermissions();
  const posthog = usePostHog();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isEventOrderPage = orderPage.order_page_type === OrderPageTypes.EVENT;
  const schema = {
    color: yup.string().required(t('editOrderPage.yup.required')),
    duration: yup.number().required(t('editOrderPage.yup.required')).positive(t('editOrderPage.yup.hasToBePoitive')),
  };
  const privateOrderSpecificSchema = {
    min_date_in_days: yup.number().min(0, t('editOrderPage.yup.hasToBePoitive')),
    max_date_in_days: yup
      .number()
      .required(t('editOrderPage.yup.required'))
      .positive(t('editOrderPage.yup.hasToBePoitive'))
      .lessThan(181, t('editOrderPage.yup.hasToBeLessThan180')),
    after_buffer_minutes: yup
      .number()
      .required(t('editOrderPage.yup.required'))
      .min(0, t('editOrderPage.yup.hasToBePoitive')),
  };

  const validationSchema = yup.object().shape(isEventOrderPage ? schema : { ...schema, ...privateOrderSpecificSchema });

  const initialValues: SettingsFormValues = {
    color: orderPage?.color || '#6577b3',
    duration: (orderPage?.duration || 0) / 60,
    min_date_in_days: orderPage.min_date_in_days,
    max_date_in_days: orderPage.max_date_in_days,
    after_buffer_minutes: orderPage.after_buffer_minutes,
    photo: orderPage.photo,
    location_id: orderPage.location_id,
  };

  const handleSubmit = async (values: SettingsFormValues) => {
    try {
      posthog.capture('Editing settings', { section: 'settingsSection', settings: values });
      const updatedAccount = await AccountService.updateOrderPage(
        omit(
          {
            ...orderPageInitialValues,
            ...values,
            id: orderPage.id,
            duration: (values.duration || 0) * 60,
          },
          'availability_settings.restrictions',
          'objects',
        ),
      );
      await updateLocalAccount(updatedAccount);
      setIsUpdatedSnackOpen(true);
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ values, isSubmitting, dirty, handleSubmit, setFieldValue }) => {
        const formIsDisabled = readOnly || isSubmitting;
        return (
          <Form onSubmit={handleSubmit}>
            <Grid container justifyContent="space-between" flexWrap="nowrap" alignItems="flex-start">
              <Grid container flexDirection="column" gap={1}>
                <Grid container alignItems="center" gap={2} mb={1}>
                  <Typography>{t('editOrderPage.address')}</Typography>
                  <Grid item>
                    <LocationPicker
                      initialLocationId={initialValues.location_id}
                      onSelected={(selectedLocation) => setFieldValue('location_id', selectedLocation)}
                      withoutLabel
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <FormFieldColorPicker
                    tooltip={t('editOrderPage.orderPageColortooltip')}
                    disabled={formIsDisabled}
                    fieldName="color"
                    label={t('editOrderPage.orderPageColor')}
                  />

                  <Grid container flexWrap="nowrap" my={4} gap={1} alignItems="center">
                    <Grid xs={4}>
                      <Typography>{t('editOrderPage.photo')}</Typography>
                    </Grid>
                    {values.photo && values.photo.length > 0 ? (
                      <Grid container alignItems="center">
                        <Grid>
                          <IconButton onClick={() => setFieldValue('photo', undefined)}>
                            <RemoveCircleOutline />
                          </IconButton>
                        </Grid>
                        <Grid border="1px solid black">
                          <img
                            src={values.photo?.replace('raw', 'image').concat('?w=200&h=200&fit=crop')}
                            width={isSmallScreen ? '120px' : '200px'}
                            alt="logo"
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <ImageUploadWidget
                        onImageUpload={(files: UploadWidgetResult[]) => setFieldValue('photo', files[0].fileUrl)}
                      />
                    )}
                  </Grid>

                  <Grid container alignItems="center" mt={2}>
                    <Grid item>
                      <Typography>{t('editOrderPage.orderPageMinDatePrefix')}</Typography>
                    </Grid>
                    <Grid item mx={1}>
                      <FormTextField
                        isFieldChanged={values.min_date_in_days !== initialValues.min_date_in_days}
                        type="number"
                        width={60}
                        small
                        fieldName="min_date_in_days"
                        disabled={formIsDisabled}
                      />
                    </Grid>
                    <Grid item>
                      <Typography>{t('editOrderPage.orderPageMinDatePostFix')}</Typography>
                    </Grid>
                    <Grid item mx={1}>
                      <FormTextField
                        isFieldChanged={values.max_date_in_days !== initialValues.max_date_in_days}
                        width={70}
                        small
                        fieldName="max_date_in_days"
                        disabled={formIsDisabled}
                        type="number"
                      />
                    </Grid>
                    <Grid item>
                      <Typography>{t('editOrderPage.orderPageMaxDatePostFix')}</Typography>
                    </Grid>
                  </Grid>

                  {!isEventOrderPage && (
                    <Grid item container flexWrap="nowrap" flexDirection={isSmallScreen ? 'column' : 'row'} mt={1}>
                      <Grid xs={isSmallScreen ? 8 : 4}>
                        <Typography>{t('editOrderPage.orderPageBuffer')}</Typography>
                      </Grid>
                      <FormTextField
                        width={100}
                        type="number"
                        isFieldChanged={values.after_buffer_minutes !== initialValues.after_buffer_minutes}
                        caption={t('editOrderPage.orderPageBufferCaption')}
                        fieldName="after_buffer_minutes"
                        disabled={formIsDisabled}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid item container flexWrap="nowrap" flexDirection={isSmallScreen ? 'column' : 'row'}>
                  <Grid xs={isSmallScreen ? 8 : 4}>
                    <Typography>{t('editOrderPage.orderPageBufferDuration')}</Typography>
                  </Grid>
                  <FormTextField
                    width={100}
                    isFieldChanged={values.duration !== initialValues.duration}
                    fieldName="duration"
                    disabled={formIsDisabled}
                    type="number"
                  />
                </Grid>
              </Grid>
              <LoadingButton
                size="large"
                loading={isSubmitting}
                type="submit"
                variant="contained"
                color="primary"
                disabled={!dirty || formIsDisabled}>
                {t('editOrderPage.orderPageBufferSubmit')}
              </LoadingButton>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};
