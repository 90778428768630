import { Grid, InputAdornment, ToggleButtonGroup, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { AttachMoney } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { OrderPageToggleButton } from '../../themedComponents/OrderPageToggleButton';
import { OrderPageTextField } from '../../themedComponents/OrderPageTextField';
import { OrderPageTypography } from '../../themedComponents/OrderPageTypography';
import { TipType } from '../../../api';
import { OrderPageButton } from '../../themedComponents/OrderPageButton';

type TipComponentProps = {
  tipInitial?: number;
  setTip: (type: TipType, value: number) => void;
  initialType?: TipType;
};

export const TipComponent = ({ tipInitial, setTip, initialType }: TipComponentProps) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const [tipValue, setTipValue] = useState<number>(initialType === TipType.FIXED ? -1 : tipInitial || 0);
  const [customValue, setCustomValue] = useState(initialType === TipType.FIXED && tipInitial ? tipInitial : 0);
  const [showCustomValue, setShowCustomValue] = useState(initialType === TipType.FIXED);
  const setTipPercentage = (e: any, val: any) => {
    if (val === -1) {
      setTipValue(-1);
      setShowCustomValue(true);
      return;
    }
    setShowCustomValue(false);
    setTipValue(val);
    setTip(val === 0 ? TipType.FIXED : TipType.PERCENTAGE, val);
  };
  const setCustomAmount = () => {
    setTipValue(customValue);
    setTip(TipType.FIXED, customValue);
  };
  const textColor = theme.customTheme.palette.text.primary;

  return (
    <Grid container flexDirection="column">
      <Grid item mb={1}>
        <OrderPageTypography>{t('tipComponent.leaveTip')}</OrderPageTypography>
      </Grid>
      <ToggleButtonGroup value={tipValue} exclusive onChange={setTipPercentage}>
        <OrderPageToggleButton key={'tip-0'} value={0}>
          <OrderPageTypography>{t('tipComponent.noTip')}</OrderPageTypography>
        </OrderPageToggleButton>
        <OrderPageToggleButton key={'tip-custom'} value={-1}>
          <OrderPageTypography>{t('tipComponent.Custom')}</OrderPageTypography>
        </OrderPageToggleButton>
        <OrderPageToggleButton key={'tip-20'} value={20}>
          18%
        </OrderPageToggleButton>
        <OrderPageToggleButton key={'tip-22'} value={22}>
          20%
        </OrderPageToggleButton>
      </ToggleButtonGroup>
      {showCustomValue && (
        <Grid container alignItems="center" gap={2} mt={2}>
          <OrderPageTypography>{t('tipComponent.customAmount')}</OrderPageTypography>
          <Grid item maxWidth="120px">
            <OrderPageTextField
              size="small"
              variant="outlined"
              type="number"
              value={customValue}
              onChange={(e) => setCustomValue(+e.target.value)}
              InputProps={{
                inputProps: { min: 0 },
                startAdornment: (
                  <InputAdornment position="end">
                    <AttachMoney style={{ width: '20px', color: textColor }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <OrderPageButton variant="outlined" onClick={setCustomAmount}>
            <OrderPageTypography>{t('tipComponent.add')}</OrderPageTypography>
          </OrderPageButton>
        </Grid>
      )}
    </Grid>
  );
};
