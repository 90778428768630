import { useEffect, useState } from 'react';
import { DateAvailabilityOut } from '../api';

type UseFilteredLocationsResult = {
  filteredLocationIds: string[];
  selectedLocations: string[];
  setSelectedLocations: React.Dispatch<React.SetStateAction<string[]>>;
  numberOfTotalLocations: number;
};

type useFilteredLocationsProps = {
  initialAvailableDates: DateAvailabilityOut[];
  defaultLocationId?: string;
};
export const useFilteredLocations = ({
  initialAvailableDates,
  defaultLocationId,
}: useFilteredLocationsProps): UseFilteredLocationsResult => {
  const [filteredLocationIds, setFilteredLocationIds] = useState<string[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [numberOfTotalLocations, setNumberOfTotalLocations] = useState(0);
  useEffect(() => {
    const allLocationsSameOrUndefined = new Set(
      initialAvailableDates.flatMap((date) =>
        date.times.map((time) => time.location_id ?? (defaultLocationId || defaultLocationId)),
      ),
    );
    const newFilteredLocationIds = Array.from(allLocationsSameOrUndefined).filter((id) => id !== undefined) as string[];
    setFilteredLocationIds(newFilteredLocationIds);
    setSelectedLocations(newFilteredLocationIds);
    setNumberOfTotalLocations(allLocationsSameOrUndefined.size);
  }, [initialAvailableDates]);

  return { filteredLocationIds, selectedLocations, setSelectedLocations, numberOfTotalLocations };
};
