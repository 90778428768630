import { styled } from '@mui/material/styles';
import { Chip } from '@mui/material';

export const OrderPageChip = styled(Chip)(({ theme }) => ({
  border: `1px solid ${theme.customTheme.palette.border}`,
  backgroundColor: 'inherit',
  color: theme.palette.primary.contrastText,
  '& .MuiChip-label': {
    fontWeight: 'bold',
  },
}));
