import { Grid, TableCell, TableRow as MuiTableRow } from '@mui/material';
import React from 'react';
import { CellProps } from './useTable';
import { PriceCell } from './PriceCell';
import { OrderData, VoucherOut } from '../api';

type TableRowProps<T> = {
  index: number;
  tableDetails: CellProps<T>[];
  item: T;
};
export const TableRow = <T,>({ index, tableDetails, item }: TableRowProps<T>) => {
  const isVoucher = (item as VoucherOut).code !== undefined;
  return (
    <MuiTableRow key={`tablerow-${index}`}>
      {tableDetails.map((cellProps) => (
        <TableCell
          align={cellProps.alignment}
          id={`row${index}cell${cellProps.id}`}
          sx={{
            minWidth: cellProps.width,
            maxWidth: cellProps.width,
            padding: cellProps.noPadding ? '0px' : undefined,
          }}>
          <Grid container justifyContent="center">
            {/* item as OrderData is for when we want to use price renderrer */}
            {cellProps.shouldUsePriceRenderer ? (
              <PriceCell orderOrVoucher={item as OrderData | VoucherOut} isVoucher={isVoucher} />
            ) : cellProps.hideCell ? null : (
              cellProps.render(item)
            )}
          </Grid>
        </TableCell>
      ))}
    </MuiTableRow>
  );
};
