import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArray, Formik } from 'formik';
import { Button, Grid, Typography } from '@mui/material';
import { KeyedMutator } from 'swr/_internal';
import { Add } from '@mui/icons-material';
import { UseAuthPermissions } from '../../session/UseAuthPermissions';
import { AccountData, LocationsStatus } from '../../api';
import { LocationItem } from './LocationItem';

type AccountLocationsSettingsProps = {
  account: AccountData;
  updateLocalAccount: KeyedMutator<AccountData>;
  setIsUpdatedSnackOpen: (value: boolean) => void;
};

export const AccountLocationsSettings = ({
  account,
  updateLocalAccount,
  setIsUpdatedSnackOpen,
}: AccountLocationsSettingsProps) => {
  const { t } = useTranslation();
  const { readOnly } = UseAuthPermissions();
  const languages = account.customer_facing_widgets_languages;

  const initialValues = { locations: account.locations || [] };

  return (
    <Formik initialValues={initialValues} onSubmit={() => {}}>
      {({ values, setValues }) => {
        return (
          <FieldArray name="locations">
            {({ push, remove }) => (
              <Grid container flexDirection="column">
                <Grid item mb={2}>
                  <Typography variant="h4">{t('locations.locationsTitle')}</Typography>
                </Grid>
                <Grid>
                  {values.locations
                    .filter((x) => x.status !== LocationsStatus.ARCHIVED)
                    .map((location, index) => (
                      <LocationItem
                        key={location.id}
                        location={location}
                        languages={languages}
                        setIsUpdatedSnackOpen={setIsUpdatedSnackOpen}
                        readOnly={readOnly}
                        removeFromValues={() => remove(index)}
                        account={account}
                        updateLocalAccount={updateLocalAccount}
                        setValues={setValues}
                      />
                    ))}
                </Grid>
                <Grid px={2}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      push({ address: { en: undefined, he: undefined }, description: {} });
                    }}>
                    <Grid container gap={1}>
                      <Add />
                      {t('locations.addLocation')}
                    </Grid>
                  </Button>
                </Grid>
              </Grid>
            )}
          </FieldArray>
        );
      }}
    </Formik>
  );
};
