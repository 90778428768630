import React from 'react';
import { TextField, TextFieldProps, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTextField = styled(TextField)(({ lineColor, textColor }: { lineColor?: string; textColor?: string }) => ({
  '& .MuiInput-underline:before': {
    borderBottomColor: lineColor,
  },
  '& .MuiInput-underline:hover:before': {
    borderBottomColor: lineColor,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: lineColor,
  },
  '& .MuiInputLabel-root': {
    color: lineColor,
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: textColor,
  },
  '& .MuiInputBase-input': {
    color: textColor,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: lineColor,
    },
    '&:hover fieldset': {
      borderColor: lineColor,
    },
    '&.Mui-focused fieldset': {
      borderColor: lineColor,
    },
  },
}));

export const OrderPageTextField = (props: TextFieldProps) => {
  const theme = useTheme();

  const textColor = theme.customTheme.palette.text.primary;
  const { lineColor } = theme.customTheme.palette;

  return <StyledTextField textColor={textColor} lineColor={lineColor} variant="standard" {...props} />;
};
