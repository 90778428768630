import { styled } from '@mui/material/styles';
import { Checkbox } from '@mui/material';

export const OrderPageCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.customTheme.palette.border,
  borderColor: theme.customTheme.palette.border,
  '&.Mui-checked': {
    color: theme.customTheme.palette.border,
    borderColor: theme.customTheme.palette.border,
  },
}));
