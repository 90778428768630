import { useTranslation } from 'react-i18next';
import { Alert, Checkbox, Divider, FormControlLabel, Grid, IconButton, Snackbar } from '@mui/material';
import React, { useState } from 'react';
import { usePostHog } from 'posthog-js/react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Typography from '@mui/material/Typography';
import { ContentCopy } from '@mui/icons-material';
import { AccountData, OrderPageData } from '../../../api';

const SyntaxHighlighterWithFix = SyntaxHighlighter as unknown as React.FC<any>;

type IntegrationSectionProps = {
  orderPage: OrderPageData;
  account: AccountData;
};
export const IntegrationSection = ({ orderPage, account }: IntegrationSectionProps) => {
  const [copySnackIsOpen, setCopySnackIsOpen] = useState(false);
  const [includeVouchers, setIncludeVouchers] = useState(false);
  const [includeCouples, setIncludeCouples] = useState(false);

  const { t } = useTranslation();

  const posthog = usePostHog();
  const buildQueryString = () => {
    const params = new URLSearchParams();
    if (includeVouchers) params.append('vouchers', 'true');
    if (includeCouples) params.append('couples', 'true');
    return params.toString();
  };
  const code =
    '<div class="monkeybook-widget-container"\n' +
    `     data-iframe-src="https://app.monkeybook.io/order/${account.id}/${account.experiences[0].id}/${
      orderPage.id
    }?${buildQueryString()}"></div>\n\n` +
    `<script src="https://widget.monkeybook.io/widget.js?account=${account.id}" defer></script>`;

  const handleClick = () => {
    setCopySnackIsOpen(true);
    void navigator.clipboard.writeText(code);
    posthog.capture('Code snippet copied', {
      accountId: account.id,
      orderPageId: account.id,
    });
  };

  return (
    <Grid mt={1} container flexDirection="column">
      <Grid>
        <Typography fontWeight={700}>{t('integrationSection.specialWidgets')}</Typography>
      </Grid>
      <Grid container flexDirection="column" mb={4}>
        <FormControlLabel
          control={<Checkbox checked={includeVouchers} onChange={(e) => setIncludeVouchers(e.target.checked)} />}
          label={t('integrationSection.includeVouchers')}
        />
        <FormControlLabel
          control={<Checkbox checked={includeCouples} onChange={(e) => setIncludeCouples(e.target.checked)} />}
          label={t('integrationSection.includeCouples')}
        />
      </Grid>
      <Divider />
      <Grid mt={4}>
        <IconButton onClick={handleClick}>
          <Grid
            px={2}
            py={1}
            gap={2}
            border="1px solid black"
            borderRadius="10px"
            container
            direction="row"
            alignItems="center">
            <ContentCopy />
            <Typography>{t('integrationSection.copyCode')}</Typography>
          </Grid>
        </IconButton>
      </Grid>

      <Grid container dir={'ltr'}>
        <SyntaxHighlighterWithFix
          wrapLines
          wrapLongLines
          language={'java'}
          style={a11yLight}
          customStyle={{
            padding: 10,
            fontSize: '14px',
            lineHeight: '20px',
            borderRadius: '10px',
            border: '1px solid black',
            backgroundColor: 'white',
          }}>
          {code}
        </SyntaxHighlighterWithFix>
      </Grid>
      <Snackbar
        open={copySnackIsOpen}
        onClose={() => setCopySnackIsOpen(false)}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setCopySnackIsOpen(false)} severity="success">
          {t('copiedToClipboard')}
        </Alert>
      </Snackbar>
    </Grid>
  );
};
