import React from 'react';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { AccessTime, Event } from '@mui/icons-material';
import { AccountData, CancellationPolicyData, OrderData, OrderPageData, PolicyTypes } from '../api';

import { getSummaryData, TableRowType } from '../order_page/orderUtils/getSummaryData';
import { OrderPageTypography } from '../order_page/themedComponents/OrderPageTypography';
import { useLanguage } from '../common/GeneralUtils';
import { priceFormatter } from '../order_page/utils';

const OrderSummary = ({
  order,
  orderPage,
  account,
  hideCancellationPolicy,
  newTime,
  newDate,
}: {
  order?: OrderData;
  newDate?: string | null;
  newTime?: number | null;
  hideCancellationPolicy?: boolean;
  orderPage?: OrderPageData;
  account?: AccountData;
}) => {
  const { t } = useTranslation();
  const language = useLanguage();
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  if (!orderPage) {
    return null;
  }

  const cancellationsPolicies = account?.cancellation_policies;

  if (!order) {
    return null;
  }

  const renderPolicyValue = (cancellationPolicy: CancellationPolicyData, account: AccountData) => {
    if (cancellationPolicy.policy_type === PolicyTypes.PERCENT) {
      return `${cancellationPolicy.value} %`;
    }
    return priceFormatter(cancellationPolicy.value, account);
  };

  const lastPolicyDays =
    cancellationsPolicies && cancellationsPolicies.length > 0
      ? cancellationsPolicies[cancellationsPolicies.length - 2]?.days_before
      : 0;
  const orderRows = getSummaryData({
    custom_details: order.custom_details,
    duration: order.duration,
    language,
    orderPage,
    persons: order.persons,
    t,
  });

  const rowsWithDate: (TableRowType & { color?: string })[] = [
    ...orderRows,
    {
      color: newDate ? '#47b406' : undefined,
      key: t('order_page.date'),
      value: moment(newDate || order.date).format('DD/MM/yyyy'),
      icon: Event,
    },
    {
      color: newTime ? '#47b406' : undefined,
      key: t('order_page.hour'),
      value: moment
        .unix(newTime || order.time || 0)
        .tz('utc')
        .format('H:mm'),
      icon: AccessTime,
    },
  ];
  return (
    <Grid
      item
      style={{
        padding: '16px',
        border: `2px solid ${theme.customTheme.palette.border}`,
        borderRadius: '8px',
      }}>
      <Table>
        <TableBody>
          {rowsWithDate.map((row: TableRowType & { color?: string }) => (
            <TableRow key={row.key}>
              <TableCell
                style={{
                  paddingTop: '5px',
                  paddingBottom: '5px',
                  paddingLeft: '0',
                  paddingRight: '0',
                  borderBottom: 'none',
                }}>
                <row.icon style={{ color: theme.customTheme.palette.primary }} />
              </TableCell>
              <TableCell width={isSmallScreen ? 90 : 150} style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                <OrderPageTypography style={{ color: row.color }} bold>
                  {row.key}
                </OrderPageTypography>
              </TableCell>
              <TableCell style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                <OrderPageTypography style={{ color: row.color }}>{row.value}</OrderPageTypography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {!hideCancellationPolicy && cancellationsPolicies && cancellationsPolicies.length > 0 && (
        <Grid container flexDirection="column" mt={6} gap={1}>
          <OrderPageTypography variant="h3">{t('manage_booking.cancalationpolicy')}</OrderPageTypography>
          <Grid item>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 600, padding: 0 }}>
                    <OrderPageTypography bold>{t('cancelation.daysbefore')}</OrderPageTypography>
                  </TableCell>
                  <TableCell style={{ fontWeight: 600, padding: 0 }}>
                    <OrderPageTypography bold>{t('cancelation.value')}</OrderPageTypography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cancellationsPolicies.map((row: CancellationPolicyData) => (
                  <TableRow key={`cancellationsPolicy${row.days_before}`}>
                    <TableCell style={{ padding: 0, paddingTop: '5px', paddingBottom: '5px' }}>
                      <OrderPageTypography>
                        {row.days_before === 0 ? t('cancelation.lessthen', { days: lastPolicyDays }) : row.days_before}
                      </OrderPageTypography>
                    </TableCell>
                    <TableCell style={{ padding: 0 }}>
                      <OrderPageTypography>{renderPolicyValue(row, account)}</OrderPageTypography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default OrderSummary;
