import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { AccountData, Language, OrderPageData } from '../../api';
import { formatDate } from '../../common/formatDate';
import { EmailTemplatesStaticContent } from './resolveTemplate';
import { getOrderPages } from '../../common/AccountUtils';

type useReplaceTemplateWIthDefaultsParams = {
  language: Language;
  account: AccountData;
  emailTemplatesStaticContent?: EmailTemplatesStaticContent;
  orderPageIdOverride?: string;
};
export const useReplaceTemplateWIthDefaults = ({
  language,
  account,
  emailTemplatesStaticContent,
  orderPageIdOverride,
}: useReplaceTemplateWIthDefaultsParams): EmailTemplatesStaticContent | undefined => {
  const { t } = useTranslation();
  const { longDateFormat } = formatDate(account);
  if (!emailTemplatesStaticContent) {
    return undefined;
  }
  const orderPages = getOrderPages(account);
  const orderPage: OrderPageData = orderPages.find((x: OrderPageData) => x.id === orderPageIdOverride) || orderPages[0];

  const replacements: {
    [key: string]: string;
  } = {
    '{{logo}}': account.logo,
    '{{logoUrl}}': account.logo,
    '{{orderPageLabel}}': orderPage.label[language] || '',
    '{{activityLabel}}': orderPage.activity_label?.[language] || 'פעילות',
    '{{location}}': account.locations[0]?.address?.[language] || '',
    '{{managerPhone}}': orderPage.phone?.[language] || '',
    '{{formattedDatetime}}': moment().format(`${longDateFormat} HH:mm`),
    '{{priceLine}}': t('emailTemplates.priceLine', { lng: language }),
    '{{notificationsEmail}}': account.notifications_email || '',
    '{{#each customOrderDetails}}<br />{{this.name}}: {{this.value}}{{/each}}{{#each customPersonalDetails}}<br />{{this.name}}: {{this.value}}{{/each}}':
      ' ',
    '{{accountAddress}}': account.locations[0]?.address?.[language] || ' ',
    '{{accountPhone}}': orderPage.phone?.[language] || ' ',
    '{{description}}': orderPage.description?.[language] || ' ',
    '{{accountName}}': account.label?.[language] || ' ',
    '{{borderColor}}': account.email_communication_settings?.border_color || '#4fbf13',
  };
  const regex = new RegExp(
    Object.keys(replacements)
      .map((key) => key.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
      .join('|'),
    'g',
  );
  const replaceContent = (
    content: string | undefined,
    regex: RegExp,
    replacements: {
      [key: string]: string;
    },
  ): string | undefined => {
    return content?.replace(regex, (matched: string): string => replacements[matched] || matched);
  };
  return {
    headerHtml: replaceContent(emailTemplatesStaticContent.headerHtml, regex, replacements)!,
    footerHtml: replaceContent(emailTemplatesStaticContent.footerHtml, regex, replacements)!,
    sendgridHtml: replaceContent(emailTemplatesStaticContent.sendgridHtml, regex, replacements),
  };
};
