import { usePriceRenderer } from '../calendar/calendar-items/usePriceRenderer';
import { OrderData, VoucherOut } from '../api';

type PriceCellProps = {
  orderOrVoucher: OrderData | VoucherOut;
  isVoucher?: boolean;
};
export const PriceCell = ({ orderOrVoucher, isVoucher }: PriceCellProps): JSX.Element => {
  const { SlimPrice } = usePriceRenderer({
    orderId: orderOrVoucher.id,
    discountId: orderOrVoucher.discount_id,
    initialPrice: orderOrVoucher.price,
    returningCustomerFromOrderId: orderOrVoucher.returning_customer_from_order_id,
    extraPaymentLines: 'extra_payment_lines' in orderOrVoucher ? orderOrVoucher.extra_payment_lines : [],
    isVoucher,
  });
  return <SlimPrice />;
};
