import React from 'react';
import { FormControlLabel, FormGroup, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AccountData, AccountLocationData, DateAvailabilityOut, Language } from '../api';
import { useLanguage } from '../common/GeneralUtils';
import { OrderPageTypography } from './themedComponents/OrderPageTypography';
import { OrderPageChip } from './themedComponents/OrderPageChip';
import { OrderPageCheckbox } from './themedComponents/OrderPageCheckbox';
import { useFilteredLocations } from './useFilteredLocations';

type MultiLocationDetailsProps = {
  initialAvailableDates: DateAvailabilityOut[];
  isLoadingDates: boolean;
  setFilteredAvailableDates: (dates: DateAvailabilityOut[]) => void;
  account: AccountData;
  defaultLocationId: string;
};
export const getLocationNameById = (
  locationId: string,
  locations: AccountLocationData[],
  language: Language,
): string | undefined => {
  const location = locations.find((location) => location.id === locationId);
  return location ? location.name?.[language] : locationId;
};
export const MultiLocationDetails = ({
  initialAvailableDates,
  isLoadingDates,
  setFilteredAvailableDates,
  account,
  defaultLocationId,
}: MultiLocationDetailsProps) => {
  const language = useLanguage();

  const { t } = useTranslation();
  const { locations } = account;
  const { filteredLocationIds, selectedLocations, setSelectedLocations } = useFilteredLocations({
    initialAvailableDates,
    defaultLocationId,
  });

  const handleToggle = (locationId: string) => {
    setSelectedLocations((prevSelected) => {
      const newSelected = prevSelected.includes(locationId)
        ? prevSelected.filter((id) => id !== locationId)
        : [...prevSelected, locationId];

      const filteredDates = initialAvailableDates.filter((date) => {
        return date.times.some((time) => newSelected.includes(time.location_id!));
      });

      setFilteredAvailableDates(filteredDates);
      return newSelected;
    });
  };
  if (!initialAvailableDates || isLoadingDates) {
    return null;
  }

  if (filteredLocationIds.filter((x) => !!x).length <= 1) {
    return null;
  }

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <OrderPageTypography variant="h3" bold>
          {t('locationSelector.title')}
        </OrderPageTypography>
      </Grid>
      <FormGroup>
        <Grid container flexWrap="nowrap" gap={2} my={2} px={6} justifyContent="center">
          {filteredLocationIds.map((locationId: string) => (
            <Grid>
              <OrderPageChip
                label={
                  <FormControlLabel
                    key={locationId}
                    control={
                      <OrderPageCheckbox
                        checked={selectedLocations.includes(locationId)}
                        onChange={() => handleToggle(locationId)}
                      />
                    }
                    label={getLocationNameById(locationId, locations, language)}
                  />
                }
              />
            </Grid>
          ))}
        </Grid>
      </FormGroup>
    </Grid>
  );
};
