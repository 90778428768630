import React, { useEffect, useState } from 'react';
import { Alert, Checkbox, Divider, FormControlLabel, Grid, Snackbar, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { KeyedMutator } from 'swr/_internal';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { EmailTemplateEditor } from './EmailTemplateEditor';
import { EmailTemplatesStaticContent } from '../utils/resolveTemplate';
import { AccountData, EmailTemplateType, Language, OrderPageData } from '../../api';
import { getOrderPages } from '../../common/AccountUtils';

type EmailTemplateEditorProps = {
  selectedLanguage: Language;
  templateType: EmailTemplateType;
  templateWithDefaults?: EmailTemplatesStaticContent;
  account: AccountData;
  updateLocalAccount: KeyedMutator<AccountData>;
  orderPageIdOverride?: string;
};

export const EmailTemplateWrapper = ({
  selectedLanguage,
  templateType,
  templateWithDefaults,
  account,
  updateLocalAccount,
  orderPageIdOverride,
}: EmailTemplateEditorProps) => {
  const isOverrideTemplates = !!orderPageIdOverride;

  const [isUpdatedSnackOpen, setIsUpdatedSnackOpen] = useState(false);
  const { t } = useTranslation();
  const orderPageOverrideCommunicationsSettings = getOrderPages(account).find(
    (x: OrderPageData) => x.id === orderPageIdOverride,
  )?.email_communication_settings;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [isEditingTemplatesEnabled, setIsEditingTemplatesEnabled] = useState(true);

  useEffect(() => {
    const overrides = orderPageOverrideCommunicationsSettings?.[templateType]?.custom_content?.[selectedLanguage];

    setIsEditingTemplatesEnabled(!isOverrideTemplates || !isEmpty(overrides));
  }, [selectedLanguage, templateType]);
  const shouldShowEditor = !isOverrideTemplates || (isOverrideTemplates && isEditingTemplatesEnabled);
  return (
    <Grid container alignItems="center" mt={2} flexDirection="column" gap={3} mb={4} pb={2}>
      <Divider style={{ width: '300px' }} />
      <Grid flexDirection="column" container alignItems="center" mt={2}>
        <Typography variant="h5">{`${t('emailTemplates.emailPreview')}`}</Typography>
        {isSmallScreen && <Typography color={'error'}>{t('emailTemplates.noEditingOnSmallScreen')}</Typography>}
      </Grid>
      {isOverrideTemplates && (
        <Link to={`/dashboard/email-templates`}>
          <Typography>{t('emailTemplates.editAllTemplates')}</Typography>
        </Link>
      )}
      {shouldShowEditor && (
        <EmailTemplateEditor
          selectedLanguage={selectedLanguage}
          setIsUpdatedSnackOpen={setIsUpdatedSnackOpen}
          account={account}
          updateLocalAccount={updateLocalAccount}
          templateWithDefaults={templateWithDefaults}
          templateType={templateType}
          orderPageIdOverride={orderPageIdOverride}
          isEditingTemplatesEnabled={isEditingTemplatesEnabled}
        />
      )}
      <Divider style={{ width: '300px', marginTop: '24px' }} />
      {isOverrideTemplates && (
        <Grid item container gap={1} justifyContent="center" alignItems="center">
          <FormControlLabel
            control={<Checkbox />}
            checked={isEditingTemplatesEnabled}
            label={t('emailTemplates.overrideTemplates')}
            onChange={() => setIsEditingTemplatesEnabled(!isEditingTemplatesEnabled)}
          />
        </Grid>
      )}

      <Snackbar
        open={isUpdatedSnackOpen}
        onClose={() => setIsUpdatedSnackOpen(false)}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert sx={{ width: '100%' }} onClose={() => setIsUpdatedSnackOpen(false)} severity="success" variant="filled">
          {t('editOrderPage.orderPageTypeSuccess')}
        </Alert>
      </Snackbar>
    </Grid>
  );
};
