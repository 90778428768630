import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { Chip, Divider, Grid, useMediaQuery, useTheme } from '@mui/material';
import { KeyedMutator } from 'swr/_internal';
import Typography from '@mui/material/Typography';
import { Delete, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { AccountData, AccountLocationData, AccountService, Language, LocationsStatus } from '../../api';
import { FormTextField } from '../../experienceSettings/formComponents/FormTextField';
import { dynamicLabelSchema } from '../../experienceSettings/sections/informationSection/dynamicLabelSchema';

type LocationFormProps = {
  location: AccountLocationData;
  languages: string[];
  setIsUpdatedSnackOpen: (isOpen: boolean) => void;
  readOnly: boolean;
  removeFromValues: () => void;
  account: AccountData;
  updateLocalAccount: KeyedMutator<AccountData>;
  setValues: any;
};

export const LocationItem = ({
  location,
  languages,
  setIsUpdatedSnackOpen,
  readOnly,
  removeFromValues,
  account,
  updateLocalAccount,
  setValues,
}: LocationFormProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  const hasNoLocations = account.locations.filter((x) => x.status !== LocationsStatus.ARCHIVED).length === 0;

  const initialValues = {
    address: location.address || {},
    name: location.name || {},
    description: location.description || {},
    id: location.id || undefined,
    default: location.default || hasNoLocations || false,
  };

  const validationSchema = yup.object().shape({
    address: yup.object().shape(dynamicLabelSchema(t, languages as Language[], true)),
    name: yup.object().shape(dynamicLabelSchema(t, languages as Language[], true)),
    description: yup.object().shape(dynamicLabelSchema(t, languages as Language[])),
  });

  // @ts-ignore
  const handleSubmit = async (values, { resetForm }) => {
    const updatedLocation = { ...location, ...values };
    try {
      if (updatedLocation.id) {
        const updatedAccount = await AccountService.updateLocation({
          ...updatedLocation,
          id: updatedLocation.id,
        });
        await updateLocalAccount(updatedAccount);
        setValues({ locations: updatedAccount.locations });
      } else {
        const updatedAccount = await AccountService.createLocation({
          ...updatedLocation,
        });
        await updateLocalAccount(updatedAccount);
        setValues({ locations: updatedAccount.locations });
        resetForm();
      }
      setIsUpdatedSnackOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async () => {
    if (!location.id) {
      removeFromValues();
      return;
    }
    try {
      const updatedAccount = await AccountService.deleteLocation(location.id);
      await updateLocalAccount(updatedAccount);
      setValues({ locations: updatedAccount.locations });

      setIsUpdatedSnackOpen(true);
    } catch (error) {
      console.error(error);
    }
  };
  const shouldColorFrameForNewItems = !location.id;
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ isSubmitting, dirty, values }) => {
        return (
          <Form>
            <Grid
              container
              flexDirection="column"
              border={`${shouldColorFrameForNewItems ? 4 : 1}px solid ${
                shouldColorFrameForNewItems ? '#66AB5B' : 'black'
              }`}
              m={2}
              p={2}
              borderRadius="8px"
              xs={11}
              lg={6}>
              <Grid container flexWrap="nowrap">
                <Grid item container gap={2} alignItems="center" flexWrap="nowrap" flexDirection={'row'}>
                  <LoadingButton
                    loading={isSubmitting}
                    onClick={handleDelete}
                    disabled={isSubmitting || readOnly || initialValues.default}
                    variant="outlined">
                    {isSmallScreen ? <Delete /> : t('locations.delete')}
                  </LoadingButton>
                  <LoadingButton
                    loading={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting || readOnly || !dirty}>
                    {isSmallScreen ? <Save /> : t('locations.save')}
                  </LoadingButton>
                </Grid>
                <Grid item alignSelf={'flex-end'}>
                  {initialValues.default && <Chip variant="outlined" label={t('locations.default')} color={'info'} />}
                </Grid>
              </Grid>
              {languages.map((lang, languageIndex) => (
                <Grid container gap={1} mt={2} key={lang} flexDirection="column">
                  <Typography fontWeight={700}>{t(`emailTemplates.${lang}`)}</Typography>
                  <Grid item>
                    <FormTextField
                      isFieldChanged={initialValues.name[lang as Language] !== values.name[lang as Language]}
                      width={300}
                      fieldName={`name.${lang}`}
                      type="text"
                      label={t('locations.name')}
                    />
                  </Grid>
                  <Grid item>
                    <FormTextField
                      isFieldChanged={initialValues.address[lang as Language] !== values.address[lang as Language]}
                      width={300}
                      fieldName={`address.${lang}`}
                      type="text"
                      label={t('locations.address')}
                    />
                  </Grid>
                  <Grid item>
                    <FormTextField
                      isFieldChanged={
                        initialValues.description[lang as Language] !== values.description[lang as Language]
                      }
                      width={300}
                      fieldName={`description.${lang}`}
                      type="text"
                      label={t('locations.description')}
                      minRows={3}
                      caption={t('locations.descriptionCaptions')}
                    />
                  </Grid>
                  <Grid pt={1}>
                    {languages.length > 1 && languageIndex < languages.length - 1 && (
                      <Divider
                        style={{
                          marginRight: isSmallScreen ? undefined : 80,
                          marginLeft: isSmallScreen ? undefined : 80,
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};
