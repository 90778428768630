import './LocationWithOptionsUiObject.scss';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Fade, ToggleButtonGroup } from '@mui/material';
import { useLanguage } from '../../common/GeneralUtils';
import LocationSearchInput from '../../common/ui/LocationSearchInput';
import { OrderPageTypography } from '../themedComponents/OrderPageTypography';
import { OrderPageToggleButton } from '../themedComponents/OrderPageToggleButton';

const LocationWithOptionsUiObject = (props) => {
  const { uiObject, setCustomOrderDetailValue, setOrderCustomDetails, errors, orderState } = props;
  const { t } = useTranslation();
  const language = useLanguage();
  const defaultvalue = uiObject.options[0].value;
  const initialOption = uiObject.options.find((o) => {
    return o.value === orderState?.details?.custom_details?.location;
  });
  const [value, setValue] = useState(initialOption?.value || defaultvalue);
  const [showLocation, setShowLocation] = useState(false);

  useEffect(() => {
    if (uiObject.data_type === 'custom') {
      setCustomOrderDetailValue(uiObject.name, defaultvalue);
    }
  }, []);

  const onValueChange = (e, val) => {
    if (val != null) {
      // for deselection

      setValue(val);
      const data = {
        [uiObject.name]: val,
        [`${uiObject.name}-time`]: null,
      };
      if (uiObject.data_type === 'custom') {
        setOrderCustomDetails(data);
      }

      const selectedOption = uiObject.options.filter((o) => {
        return o.value === val;
      })[0];

      if (selectedOption.type === 'dynamic') {
        // currently not showing location calculation
        setShowLocation(false); // true
        data.extraDistanceFee = true;
        setOrderCustomDetails(data);
      } else {
        setShowLocation(false);
        data.extraDistanceFee = false;
        setOrderCustomDetails(data);
      }
    }
  };

  const onLocationChange = (location) => {
    // duration needs sometimes to be declared even if max_travel is not defined. in order to have origin.
    // we need the travel duration for pricing purposes. need to be refactored.
    // let duration;
    // if (uiObject.max_travel) {
    //   duration = await GoogleMapsApi.getTravelDistance(uiObject.max_travel.origin, location);
    //   delete errors[uiObject.name];
    //   if (duration > uiObject.max_travel.time) {
    //     errors[uiObject.name] = t('order_page.max_travel_error', {
    //       duration: TimeUtils.formatDuration(uiObject.max_travel.time, t),
    //     });
    //   }
    // }

    if (uiObject.data_type === 'custom') {
      const data = {
        location: location.target.value,
      };

      setOrderCustomDetails(data);
    }
  };

  return (
    <div className="location-with-options-ui-object">
      <OrderPageTypography variant="h3" bold>
        {uiObject.label[language]}
      </OrderPageTypography>
      <div className="toggle-button-container">
        <ToggleButtonGroup orientation="vertical" value={value} color="primary" exclusive onChange={onValueChange}>
          {uiObject.options.map((option) => {
            return (
              <OrderPageToggleButton key={option.value} className="toggle-button" value={`${option.value}`}>
                {option.label[language]}
              </OrderPageToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </div>

      {showLocation && (
        <Fade in={showLocation} timeout={800}>
          <Box
            className="flex align-items-center"
            sx={{
              paddingTop: '20px',
              paddingInlineStart: '10px',
              paddingInlineEnd: '30px',
            }}>
            <Box sx={{ paddingInlineEnd: '20px' }}>{t('order_page.location')}</Box>
            <div className="flex-1">
              <LocationSearchInput error={errors[uiObject.name]} onChange={onLocationChange} />
            </div>
          </Box>
        </Fade>
      )}
    </div>
  );
};

export default LocationWithOptionsUiObject;
