import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

export const ManageLocations = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Grid
      alignContent="center"
      onClick={() => {
        navigate('/dashboard/account-settings#locations');
      }}
      style={{ cursor: 'pointer' }}>
      <Typography fontSize="12px" color={'grey'} sx={{ textDecoration: 'underline' }}>
        {t('locationPicker.settingsLink')}
      </Typography>
    </Grid>
  );
};
