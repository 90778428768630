import { Alert, Fade, Grid, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';
import RotatingLoader from '../common/ui/RotatingLoader';
import OrderSummary from './OrderSummary';
import { OrderPageTypography } from '../order_page/themedComponents/OrderPageTypography';
import { useLanguage } from '../common/GeneralUtils';
import { OrderPageButton } from '../order_page/themedComponents/OrderPageButton';
import { AccountData, DateAvailabilityOut, Language, OrderData, OrderService } from '../api';
import DateAndTimeStep from '../order_page/steps/dateAndTimeStep/DateAndTimeStep';
import { OrderPageIntent } from '../order_page/OrderPage';
import { CollectOrderDetailsStep } from './CollectOrderDetailsStep';
import { getOrderPage } from '../common/AccountUtils';

enum WizardSteps {
  ENTER_ORDER_DETAILS,
  ORDER_SUMMARY,
  CALENDAR,
  CONFIRMATION,
  DONE,
}

enum WizardError {
  NO_ERROR,
  DATE_TIME_MISSING,
  ORDER_NOT_FOUND,
  ORDER_PRICE_NOT_MATCHING,
  ORDER_DATE_IN_THE_PAST,
}

function calcIsAmendingAllowed(order: OrderData | undefined, account: AccountData | undefined) {
  if (!order || !account) {
    return false;
  }
  if (order.allowed_to_reschedule) {
    return true;
  }
  const cancellationsPolicies = account.cancellation_policies;
  if (!cancellationsPolicies) {
    return true;
  }
  const firstPolicyDays =
    cancellationsPolicies && cancellationsPolicies.length > 0 ? cancellationsPolicies[0]?.days_before : 0;
  const now = moment();
  const cancellationLimit = moment(order.date).subtract(firstPolicyDays, 'days');
  return now <= cancellationLimit;
}

type ManageBookingWizardProps = {
  setLanguages: (languages: Language[]) => void;
};

export const ManageBookingWizard = ({ setLanguages }: ManageBookingWizardProps) => {
  const posthog = usePostHog();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslation();
  const language = useLanguage();
  const [order, setOrder] = useState<OrderData | undefined>();
  const [account, setAccount] = useState<AccountData | undefined>();
  const orderPage = getOrderPage(account, order?.order_page_id || '');
  const [activeStep, setActiveStep] = useState<WizardSteps>(WizardSteps.ENTER_ORDER_DETAILS);
  const [availableDates, setAvailableDates] = useState<DateAvailabilityOut[]>([]);
  const [isLoadingDates, setIsLoadingDates] = useState(false);
  const [date, setDate] = useState<string | undefined | null>(order?.date);
  const [time, setTime] = useState<number | undefined | null>(order?.time);
  const [newPrice, setNewPrice] = useState(order?.price);
  const [wizardError, setWizardError] = useState<WizardError>(WizardError.NO_ERROR);
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [searchParams] = useSearchParams();

  const orderId = searchParams.get('orderid');
  const isEventOrderPage = orderPage?.order_page_type === 'event';
  useEffect(() => {
    if (account?.customer_facing_widgets_languages) {
      setLanguages(account?.customer_facing_widgets_languages);
    }
  }, [account?.customer_facing_widgets_languages]);

  useEffect(() => {
    const getOrder = async () => {
      if (orderId) {
        setLoadingOrder(true);
        try {
          const retrievedOrder = await OrderService.fetchOrderById(orderId);
          setOrder(retrievedOrder.order as OrderData);
          setAccount(retrievedOrder.account as AccountData);
          setActiveStep(WizardSteps.ORDER_SUMMARY);
        } catch (e) {
          setWizardError(WizardError.ORDER_NOT_FOUND);
        }

        setLoadingOrder(false);
      }
    };
    void getOrder();
  }, [orderId]);
  useEffect(() => {
    if (!order) {
      return;
    }
    if (moment(order.date) <= moment() && !order.allowed_to_reschedule) {
      setWizardError(WizardError.ORDER_DATE_IN_THE_PAST);
    }
  }, [order]);

  const orderDetails = {
    persons: order?.persons,
    duration: order?.duration,
    price: order?.price,
    rooms: order?.rooms,
    custom_details: order?.custom_details,
  };
  useEffect(() => {
    void (async () => {
      if (order && account) {
        setIsLoadingDates(true);

        const response = await OrderService.getAvailableDates({
          account_id: account.id,
          order_page_id: order.order_page_id,
          // @ts-ignore
          order_details: orderDetails,
        });
        setAvailableDates(response);
        setIsLoadingDates(false);
      }
    })();
  }, [order]);
  const getNewParentId = () => {
    const timeFound = availableDates.find((x) => x.date === date)?.times.find((t) => t.seconds === time);

    if (timeFound?.eventId) {
      return timeFound.eventId;
    }
    console.error('didnt find eventid');
    return order?.parent_id;
  };
  const handleNext = async () => {
    if (activeStep === WizardSteps.ORDER_SUMMARY) {
      setActiveStep(WizardSteps.CALENDAR);
    } else if (activeStep === WizardSteps.CALENDAR) {
      if (!date || !time) {
        setWizardError(WizardError.DATE_TIME_MISSING);
        return;
      }
      if (newPrice !== order?.price) {
        setWizardError(WizardError.ORDER_PRICE_NOT_MATCHING);
        return;
      }
      setWizardError(WizardError.NO_ERROR);
      setActiveStep(WizardSteps.CONFIRMATION);
    } else if (activeStep === WizardSteps.CONFIRMATION) {
      const payload = {
        order_id: order?.id,
        date,
        time,
        new_parent_id: isEventOrderPage ? getNewParentId() : undefined,
      };
      // @ts-ignore
      await OrderService.amendOrder(payload);
      posthog.capture('Order Edited', { editor: 'client', payload });
      setActiveStep(WizardSteps.DONE);
    }
  };

  const isAmendingAllowed = calcIsAmendingAllowed(order, account);

  function calculateErrorText(wizardError: WizardError): string {
    switch (wizardError) {
      case WizardError.DATE_TIME_MISSING:
        return t('manage_booking.errors.dateTimeMissing');
      case WizardError.ORDER_NOT_FOUND:
        return t('manage_booking.errors.orderNotFound');
      case WizardError.ORDER_PRICE_NOT_MATCHING:
        return t('manage_booking.errors.orderPriceNotMatching');
      case WizardError.ORDER_DATE_IN_THE_PAST:
        return t('manage_booking.errors.orderPassed');
      case WizardError.NO_ERROR:
      default:
        return 'No error.';
    }
  }

  const logo = account?.logo;

  function calculateCtaText() {
    switch (activeStep) {
      case WizardSteps.ORDER_SUMMARY:
        return t('manage_booking.cta.ordersummery');
      case WizardSteps.CALENDAR:
        return t('manage_booking.cta.calendar');
      case WizardSteps.CONFIRMATION:
        return t('manage_booking.cta.confirmation');
      default:
        return 'Cta text';
    }
  }

  return loadingOrder ? (
    <Grid container height="500px">
      <RotatingLoader />
    </Grid>
  ) : (
    <Grid
      container
      flexDirection="column"
      alignContent="center"
      p={isSmallScreen ? 2 : 4}
      alignItems="center"
      gap={isSmallScreen ? 2 : 6}>
      {activeStep === WizardSteps.ENTER_ORDER_DETAILS && (
        <CollectOrderDetailsStep
          setNotFound={() => setWizardError(WizardError.ORDER_NOT_FOUND)}
          setOrder={(order: OrderData, account: AccountData) => {
            setWizardError(WizardError.NO_ERROR);
            setOrder(order);
            setAccount(account);
            setActiveStep(WizardSteps.ORDER_SUMMARY);
          }}
        />
      )}
      <Grid container flexDirection="column" alignContent="center" alignItems="center" gap={1}>
        {logo && <img width="120" src={logo} alt="www.Monkey.io logo" />}
        {orderPage && <OrderPageTypography variant="h3">{orderPage?.label[language]}</OrderPageTypography>}
      </Grid>

      {activeStep === WizardSteps.ORDER_SUMMARY && (
        <OrderSummary order={order} orderPage={orderPage} account={account} />
      )}
      {!isAmendingAllowed && order && wizardError === WizardError.NO_ERROR && (
        <Grid container flexDirection="column" alignItems="center">
          {t('manage_booking.amendingNotAllowed')}
        </Grid>
      )}

      {activeStep === WizardSteps.CALENDAR && (
        <Grid container flexDirection="column">
          <DateAndTimeStep
            initialAvailableDates={availableDates}
            locations={account?.locations || []}
            orderPage={orderPage}
            isLoadingDates={isLoadingDates}
            availableDates={availableDates}
            orderPageIntent={OrderPageIntent.MANAGE_BOOKING}
            // @ts-ignore
            orderDetails={orderDetails}
            setDateTimeAndPrice={({ newDate, newTime, newPrice }) => {
              setTime(newTime);
              setDate(newDate);
              setNewPrice(newPrice);
            }}
            setTime={setTime}
            date={date}
            time={time}
          />
        </Grid>
      )}
      {wizardError !== WizardError.NO_ERROR && (
        <Fade in timeout={1000}>
          <Alert sx={{ width: '230px' }} severity="error" variant="outlined">
            <OrderPageTypography color="error">{calculateErrorText(wizardError)}</OrderPageTypography>
          </Alert>
        </Fade>
      )}
      {activeStep === WizardSteps.CONFIRMATION && (
        <OrderSummary
          order={order}
          orderPage={orderPage}
          account={account}
          newDate={date}
          newTime={time}
          hideCancellationPolicy
        />
      )}
      {activeStep === WizardSteps.DONE && (
        <Grid container justifyContent="center">
          <OrderPageTypography>{t('manage_booking.done')}</OrderPageTypography>
        </Grid>
      )}
      {activeStep !== WizardSteps.DONE && isAmendingAllowed && (
        <OrderPageButton variant="outlined" onClick={handleNext}>
          <OrderPageTypography>{calculateCtaText()}</OrderPageTypography>
        </OrderPageButton>
      )}
    </Grid>
  );
};
