import { styled } from '@mui/material/styles';
import { Divider } from '@mui/material';

export const OrderPageDivider = styled(Divider)(({ theme, children }) => ({
  '&.MuiDivider-root': {
    borderTop: children ? 'none' : `thin solid ${theme.customTheme.palette.border}`,

    '&::before': {
      borderTop: `thin solid ${theme.customTheme.palette.border}`,
    },
    '&::after': {
      borderTop: `thin solid ${theme.customTheme.palette.border}`,
    },
  },
  '& .MuiDivider-wrapper': {
    fontSize: 16,
  },
}));
