import Typography from '@mui/material/Typography';
import { Button, Divider, Grid, useMediaQuery } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { FormTextField } from '../../experienceSettings/formComponents/FormTextField';
import { getCurrencySymbol } from '../../order_page/utils';
import { AccountData } from '../../api';
import { useAuth } from '../../session/InternalAuthProvider';

type OrderPageDetailsProps = {
  handleBack: () => void;
  baseValue?: number;
  isSubmitting: boolean;
};
export const OrderPageDetails = ({ handleBack, baseValue, isSubmitting }: OrderPageDetailsProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const textBoxWidth = isSmallScreen ? 300 : 500;
  const languages = account.customer_facing_widgets_languages;

  const currencySymbol = getCurrencySymbol(authState.account as unknown as AccountData);
  return (
    <Grid container flexDirection="column" p={2}>
      <Typography variant="h5">{t('editOrderPage.pricing')}</Typography>
      <Typography variant="body2" color={theme.customTheme.palette.warning}>
        {t('editOrderPage.pricingAlert')}
      </Typography>
      <Divider />
      <Grid container alignItems="center" mt={2}>
        <Grid item>
          <Typography>{t('editOrderPage.basePrice')}</Typography>
        </Grid>
        <Grid item mx={1}>
          <FormTextField
            type="number"
            width={100}
            small
            fieldName="base_price"
            label={''}
            endAdornment={currencySymbol}
          />
        </Grid>
        <Typography>{t('editOrderPage.for')}</Typography>
        <Grid item mx={1}>
          <FormTextField type="number" width={100} small fieldName={`base_value`} label={''} />
        </Grid>
        <Grid item>
          <Typography>
            {baseValue && baseValue === 1 ? t('editOrderPage.person') : t('editOrderPage.persons')}
          </Typography>
        </Grid>
      </Grid>

      <Grid container alignItems="center" mt={1}>
        <Typography>{t('editOrderPage.forAdditional')}</Typography>
        <Grid item mx={1}>
          <FormTextField
            type="number"
            width={100}
            small
            fieldName={`price_per_additional_value`}
            endAdornment={currencySymbol}
            label={''}
          />
        </Grid>
      </Grid>

      <Grid container my={3} gap={2} flexDirection="column">
        <Typography variant="h5">{t('editOrderPage.basicInformation')}</Typography>
        <Divider />

        {languages.map((lang) => (
          <Grid key={lang}>
            <FormTextField
              key={`label.${lang}`}
              width={textBoxWidth}
              label={t(`editOrderPage.orderPageNameLang.${lang}`)}
              fieldName={`label.${lang}`}
              caption={t('editOrderPage.orderPageNameCaption')}
            />
          </Grid>
        ))}
      </Grid>
      <Grid container justifyContent="space-between">
        <Button variant="outlined" onClick={handleBack}>
          {t('back')}
        </Button>
        <LoadingButton size="large" loading={isSubmitting} type="submit" variant="contained" color="primary">
          {t('editOrderPage.createOrderPage')}
        </LoadingButton>
      </Grid>
    </Grid>
  );
};
